const SET_HAS_DATA_LOADED = 'SET_HAS_DATA_LOADED'

const state = () => ({
  has_data_loaded: false
})

const mutations = {
  [SET_HAS_DATA_LOADED] (state, payload) {
    state.has_data_loaded = payload
  }
}

const getters = {
  getHasDataLoaded: (state) => {
    return state.has_data_loaded
  }
}

const actions = {
  setDataHasLoaded ({ commit }, payload) {
    commit(SET_HAS_DATA_LOADED, payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
