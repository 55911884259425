export const EVENTS = {
  NEW_RENTAL: 'new_rental',
  NEW_RESERVATION: 'new_reservation',
  NEW_PAYMENT: 'new_payment',
  ACCOUNT: 'account',
  CONTACT: 'contact',
  MOVE_OUT: 'move_out',
  AUTO_PAY: 'auto_pay',
  PASSWORD: 'password'
}

const state = () => ({
  events: {
    [EVENTS.NEW_RENTAL]: 'New Rental',
    [EVENTS.NEW_RESERVATION]: 'New Reservation',
    [EVENTS.NEW_PAYMENT]: 'New Payment',
    [EVENTS.ACCOUNT]: 'Account',
    [EVENTS.CONTACT]: 'Contact',
    [EVENTS.MOVE_OUT]: 'Move Out',
    [EVENTS.AUTO_PAY]: 'Auto Pay',
    [EVENTS.PASSWORD]: 'Password'
  },
  selectedEvent: 'New Reservation'
})

const getters = {
  events: state => state.events,
  selectedEvent: state => state.selectedEvent
}

const mutations = {
  setSelectedEvent (state, payload) {
    state.selectedEvent = payload
  }
}

const actions = {
  setSelectedEvent ({ commit }, payload) {
    if (EVENTS[payload]) {
      commit('setSelectedEvent', EVENTS[payload])
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
