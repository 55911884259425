import { EventBus } from "@/utils/event-bus"


export const state = () => ({
  activePopupIndex: 0,
  numPopups: 0,
  isTestMode: false,
  testModePhone: '',
  activityPopups: [],
  activityPopupsCache: {},
  activityPopupsActions: {
    log_note: {
      event_action: "Note",
      card_title: "Add note",
    },
    log_call: {
      event_action: "Phone call logged",
      card_title: "Log phone call",
    },
    log_email: {
      event_action: "Email logged",
      card_title: "Log email",
    },
    move_out: {
      event_action: "Move out",
      card_title: "Log move out",
    },
    move_in: {
      event_action: "Move in",
      card_title: "Log move in",
    },
    log_review_sms_sent: {
      event_action: "SMS sent for a review",
      card_title: "Invite to write a review",
    },
  },
})

export const actions = {
  async addActivityPopupBulk({ commit, rootGetters, state }, payload) {
    commit('addActivityPopup', { isBulk: true, ...payload })
    await timeout(200)
    commit('setActivePopupIndex', 0)
    // commit('setActivityPopupCache', { index: `${ payload.activity.inferred_user_id }`, index2: `${ payload.activity.type }`, activity: payload.activity })
  },
  async addActivityPopup({ commit, rootGetters, state }, payload) {
    let index = 0
    let found = false
    for (let item of state.activityPopups) {
      if (item.isBulk == false && item.activity.type === payload.activity.type && item.activity.inferred_user_id === payload.activity.inferred_user_id) {
        // state.activePopupIndex = index
        commit('setActivePopupIndex', index)
        found = true
        break
      }
      else if (item.isBulk == false && item.contact.id === payload.contact.id) {
        if (state.activityPopupsCache[`${ payload.activity.inferred_user_id }`] && state.activityPopupsCache[`${ payload.activity.inferred_user_id }`][`${ payload.activity.type }`]) {
          // state.activityPopups.push({contact: payload.contact, activity: state.activityPopupsCache[`${payload.activity.inferred_user_id}`][`${payload.activity.type}`]})
          // state.activityPopups.splice(index, 1)
          commit('removeActivityPopup', index)
          await timeout(150)
          // state.numPopups = state.activityPopups.length
          let tmp = { contact: payload.contact, activity: state.activityPopupsCache[`${ payload.activity.inferred_user_id }`][`${ payload.activity.type }`], isBulk: false }
          if (payload.index) {
            tmp.index = payload.index
          }
          commit('addActivityPopup', tmp)
          await timeout(200)
          commit('setActivePopupIndex', 0)
          // state.activityPopups.push()
          // state.activityPopups[index] = { contact: payload.contact, activity: state.activityPopupsCache[`${payload.activity.inferred_user_id}`][`${payload.activity.type}`] }
          found = true
        }
        else {
          // state.activityPopups.splice(index, 1)
          commit('removeActivityPopup', index)
          // state.numPopups = state.activityPopups.length
          await timeout(50)
          // state.activityPopups.push(payload)
          commit('addActivityPopup', { isBulk: false, ...payload })
          await timeout(200)
          commit('setActivePopupIndex', 0)

          commit('setActivityPopupCache', { index: `${ payload.activity.inferred_user_id }`, index2: `${ payload.activity.type }`, activity: payload.activity })

          found = true
        }

        break
      }
      index++
    }
    if (found === false && state.activityPopupsCache[`${ payload.activity.inferred_user_id }`] && state.activityPopupsCache[`${ payload.activity.inferred_user_id }`][`${ payload.activity.type }`]) {
      // state.activityPopups.push({ contact: payload.contact, activity: state.activityPopupsCache[`${payload.activity.inferred_user_id}`][`${payload.activity.type}`] })
      commit('addActivityPopup', { contact: payload.contact, activity: state.activityPopupsCache[`${ payload.activity.inferred_user_id }`][`${ payload.activity.type }`], isBulk: false })
      await timeout(200)
      commit('setActivePopupIndex', 0)
      // state.activePopupIndex = state.activityPopups.length - 1
      // state.numPopups = state.activityPopups.length
    }
    else if (found === false) {
      commit('addActivityPopup', { isBulk: false, ...payload })
      await timeout(200)
      commit('setActivePopupIndex', 0)
      commit('setActivityPopupCache', { index: `${ payload.activity.inferred_user_id }`, index2: `${ payload.activity.type }`, activity: payload.activity })
      // state.activityPopups.push(payload)
      // state.activityPopupsCache[`${payload.activity.inferred_user_id}`] = {}
      // state.activityPopupsCache[`${payload.activity.inferred_user_id}`][`${payload.activity.type}`] = payload.activity
      // state.activePopupIndex = state.activityPopups.length - 1
      // state.numPopups = state.activityPopups.length
    }
  },
}

export const mutations = {
  setActivePopupIndex(state, payload) {
    state.activePopupIndex = payload
  },
  setNumPopups(state, payload) {
    state.numPopups = payload
  },
  resetActivityPopups(state, payload) {
    state.activityPopups = []
    // state.activityPopupsCache = {}
  },
  setActivityPopups(state, payload) {
    state.activityPopups = payload
  },
  addActivityPopup(state, payload) {
    state.activityPopups.unshift({})
    state.activityPopups[0] = payload
    EventBus.$emit('close_all_activity_popups')
    // state.activePopupIndex = 0
    state.numPopups = state.activityPopups.length
  },
  setActivityPopupCache(state, payload) {
    state.activityPopupsCache[payload.index] = {}
    state.activityPopupsCache[payload.index][payload.index2] = payload.activity
  },
  updateActivityPopup(state, payload) {
    state.activityPopups[payload.index].activity = payload.data
    state.activityPopupsCache[`${ payload.data.inferred_user_id }`][`${ payload.data.type }`] = payload.data
  },

  removeActivityPopup(state, payload) {
    state.activityPopups.splice(payload, 1)
    state.numPopups = state.activityPopups.length
    // state.activityPopupsCache[payload.type] = payload
  },
  removeActivityPopupAndClearCache(state, payload) {
    delete state.activityPopupsCache[`${ state.activityPopups[payload].activity.inferred_user_id }`][`${ state.activityPopups[payload].activity.type }`]
    state.activityPopups.splice(payload, 1)
    state.numPopups = state.activityPopups.length
  },
  removeActivityPopupAndClearCacheBulk(state, payload) {
    state.activityPopups.splice(payload, 1)
    state.numPopups = state.activityPopups.length
  },
  setIsTestMode(state, payload) {
    state.isTestMode = payload
  },
  setTestModePhone(state, payload) {
    state.testModePhone = payload
  },
}
function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
export const getters = {
  getActivePopupIndex(state) {
    return state.activePopupIndex
  },
  getNumPopups(state) {
    return state.numPopups
  },
  getIsTestMode(state) {
    return state.isTestMode
  },
  getTestModePhone(state) {
    return state.testModePhone
  },
  getActivityPopups(state) {
    return state.activityPopups
  },
  getActivityPopupsActions: (state) => (index) => {
    return state.activityPopupsActions[index]
  },
}
