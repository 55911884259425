import _ from "lodash"
import { ApiFactory } from "@/utils/apis/ApiFactory"
const AccountApi = ApiFactory.get("account")
const SettingsApi = ApiFactory.get("setting")
import Vue from "vue"
import { generateApiUrl } from "@/utils/helpers"

// Mutation Const
const UPDATE_ACCOUNT = "UPDATE_ACCOUNT"
const UPDATE_ACCOUNT_CLIENT_CODE = "UPDATE_ACCOUNT_CLIENT_CODE"
const UPDATE_ACCOUNT_SETTINGS = "UPDATE_ACCOUNT_SETTINGS"
const UPDATE_ACCOUNT_GLOBAL_SETTINGS = "UPDATE_ACCOUNT_GLOBAL_SETTINGS"
const SET_ACCOUNTS = "SET_ACCOUNTS"
const SET_SELECTED_ACCOUNT = "SET_SELECTED_ACCOUNT"

const state = () => ({
  accounts: [],
  global_settings: null,
  selectedAccount: {},
  // rhsClientCodes: ["a1ParkAndStore", "aaa", "allSeasons", "americanWakeForest", "antonelli", "attic60", "bannister", "bargainStorage", "bigOrange", "bowdon", "careFree", "caseys", "cherryStorage", "choto", "cleburne", "cows", "creekBank", "daltonPike", "eastWoodmen", "econovault", "evans", "falconPoint", "harrisburgEast", "haywoodCongaree", "highlands", "justBoxIt", "kansasExpwy", "landmark", "littleVine", "mobile1", "northGate", "northside", "oldGlory", "proStorageCenter", "quickQualityStorage", "ranpac", "rhs", "ridgeRoad", "shamrock", "sherlake", "snapp", "spaceMaker", "storageCorral", "storageZone", "storagesRUs", "strand", "sunshine", "tomballGrand", "tt"]
  rhsClientCodes: []
})

const getters = {
  /**
   * Return all accounts
   */
  getAccounts: (state) => {
    return state.accounts
  },
  getGlobalSettings: (state) => {
    return state.global_settings
  },
  getRhsClientCodes: (state) => {
    return state.rhsClientCodes
  },
  /**
   * Return selected account. The selected account ID is found in the
   * user's store under `user/getSelectedAccountId`
   */
  getSelectedAccount: (state, getters, rootState, rootGetters) => {
    // const id = rootGetters['user/getSelectedAccountId']
    // // let index = _.findIndex(state.accounts, function (o) { return o.id == state.user.selected_account_id; });
    // let account = state.accounts.find((opt) => opt.id === id)
    // // eslint-disable-next-line prefer-const
    // let data = _.cloneDeep(account)
    // if (data !== undefined && (data.settings === undefined || data.settings === null)) {
    //   data.settings = rootGetters['settings/getDefaultSettings']
    // }
    return state.selectedAccount
  },
  /**
   * Return account by ID
   */
  getSelectedAccountById: (state, getters, rootState, rootGetters) => (id) => {
    // eslint-disable-next-line prefer-const
    let account = state.accounts.find((opt) => opt.id === id)
    // eslint-disable-next-line prefer-const
    let data = _.cloneDeep(account)
    if (data !== undefined && (data.settings === undefined || data.settings === null)) {
      data.settings = rootGetters["settings/getDefaultSettings"]
    }
    return data
  },
}

const actions = {
  nuxtServerInit({ dispatch }, { req }) {
    // dispatch('accounts/setAccounts', {
    //   accounts: '6de4b434-fb7a-40ed-8bab-ed2f8418ca21',
    //   relations:
    //     'address,settings,locations,locations.hours,locations.address,websites,units'
    // })
  },
  /**
   * Call API and create new account with name. Default
   * account settings will be handle on the backend.
   */
  createAccount({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data: response } = await AccountApi.createAccount(payload)
        return resolve({ success: true, data: response.data })
      } catch (error) {
        Vue.rollbar.error('Store: Error creating an account', error)
        return reject(error)
      }
    })
  },
  updateClientCode({ commit, rootGetters }, account) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data: res } = await AccountApi.updateClientCode(account.client_code, account.id)
        if (res.success === true) {
          commit(UPDATE_ACCOUNT_CLIENT_CODE, account)
        }
        return resolve(res)
      } catch (error) {
        Vue.rollbar.error('Store: Error updating client code', error, { account_id: account.id })
        return reject(error)
      }
    })
  },
  setSelectedAccount({ commit }, account_id) {
    commit(SET_SELECTED_ACCOUNT, account_id)
  },
  updateAccount({ commit }, account) {
    return new Promise(async (resolve, reject) => {
      try {
        let { data: res } = await AccountApi.update(account, account.id, "address,review_settings,websites,main_image,demo_unit_variables,global_settings,global_settings.schema")
        commit(UPDATE_ACCOUNT, res.data)
        // console.log(res.data, account)
        return resolve()
      } catch (e) {
        return reject(e)
      }
    })
  },
  updateAccountSettings({ commit, getters, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      const account = getters.getSelectedAccount
      const settings = { ...account.settings, ...payload.settings }
      // console.log(settings)

      // Added current user to last edited field
      const user = rootGetters["user/getUser"]
      if (user) {
        settings.last_edited_by = user
      }

      try {
        if (payload.settings.id !== undefined && payload.settings.id !== null) {
          const { data: res } = await SettingsApi.update(settings, payload.id)
          commit(UPDATE_ACCOUNT_SETTINGS, payload)
          return resolve(true)
        } else {
          settings.account = {
            id: payload.id,
          }

          const res = await SettingsApi.update(settings, payload.id)
          commit(UPDATE_ACCOUNT_SETTINGS, {
            id: payload.id,
            settings: res.data.data,
          })
          return resolve(true)
        }
      } catch (e) {
        Vue.rollbar.error('Store: Error updating account settings', e)
        return reject(e)
      }
    })
  },
  updateAccountGlobalSettings({ commit, getters, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      let account = getters.getSelectedAccount
      let url = generateApiUrl(`${ account.id }/global-settings`)

      try {
        let settings = _.map(payload, 'global_settings')
        let { data: res } = await Vue.axios.post(url, settings)
        resolve(res.data)
      } catch (e) {
        reject(e)
      }
    })
  },

  setOnlyAccounts({ commit, dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      // Make async API call and mutate store
      try {
        let relations = "address,review_settings,websites"
        const accounts_payload = await AccountApi.all(payload.account_id, relations, null, null, "name")

        // Mutate store
        commit(SET_ACCOUNTS, accounts_payload.data.data)
        return resolve()
      } catch (e) {
        Vue.rollbar.error('Store: Error setting accounts in store', e)
        return reject()
      }
    })
  },
  /**
   * Initialize Vuex with accounts
   *
   * NOTE / Question:
   *  - In V1 should we query one large (relational) account(s) object?
   *  - In V2 should we query a single depth (no relations) account,
   *    and then set other Vuex modules (media, locations, etc) with subsequent API calls?
   */
  setAccounts({ commit, dispatch, getters, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      // Make async API call and mutate store
      try {
        const accounts_payload = await AccountApi.all(payload.account_id, payload.relations, null, null, "name")

        // Mutate store
        commit(SET_ACCOUNTS, accounts_payload.data.data)
        let account_id = rootGetters["user/getSelectedAccountId"]
        commit(SET_SELECTED_ACCOUNT, account_id)
        const account = getters.getSelectedAccount

        if (account && account.client_code) {
          dispatch("charts/setFilteredClients", [account.client_code], { root: true })
        }

        /**
         * Dispatch calls to fill up other stores with Account specific information
         *
         * @TODO Add calls to fill up settings store
         * - Call to fill Location settings--this probably should hapeen in the locations store
         * - Call to fill Website Settings
         */
        // Vue.rollbar.error('Here is some debug message')
        await Promise.all([
          dispatch("website/setWebsiteOptions", null, { root: true }),
          dispatch("website/setMenus", null, { root: true }),
          dispatch("media/setMedia", null, { root: true })
        ])
        try {
          await dispatch("features/setBetaAndComingSoonFeatures", null, { root: true })
        } catch (e) {
          console.log("🚀 ~ file: accounts.js ~ line 199 ~ returnnewPromise ~ e", e)
        }
        // await dispatch('reviews/setPermissions', null, { root: true })a
        resolve(true)
        dispatch("media/setMediaTags", null, { root: true })

        // TODO: don't call this on every page load/only call to get the needed components
        // Set component schemas and components
        dispatch("editorSidebar/setComponents", null, { root: true })

        // Should only be called on the Blog Posts page
        // dispatch('blog/setBlogs', null, { root: true })
        // dispatch('notifications/setEmailNotificationEvents', null, {
        //   root: true
        // })
        dispatch("notifications/setEmailNotifications", null, {
          root: true,
        })
        // dispatch('contact/setContacts', null, { root: true })
      } catch (error) {
        Vue.rollbar.error('Store: Error setting store with data', error)
        return reject(error)
      }
    })
  },
}

/**
 * After appropriate API calls have been made and received,
 * update the Vuex state with the new objects / values.
 */
const mutations = {
  resetAccounts(state, payload) {
    state.accounts = []
  },
  setGlobalSettings(state, payload) {
    state.global_settings = payload
  },
  [SET_SELECTED_ACCOUNT](state, account_id) {
    if (account_id !== null) {
      const index = _.findIndex(state.accounts, function (o) {
        return o.id === account_id
      })
      state.selectedAccount = state.accounts[index]
    } else state.selectedAccount = null
  },
  [UPDATE_ACCOUNT](state, account) {
    state.selectedAccount = account
    const index = _.findIndex(state.accounts, function (o) {
      return o.id === account.id
    })
    state.accounts[index] = account
  },
  [UPDATE_ACCOUNT_CLIENT_CODE](state, account) {
    const index = _.findIndex(state.accounts, function (o) {
      return o.id === account.id
    })
    state.accounts[index].client_code = account.client_code
  },
  [UPDATE_ACCOUNT_SETTINGS](state, payload) {
    const index = _.findIndex(state.accounts, function (o) {
      return o.id === payload.id
    })

    const newSettings = {
      ...state.accounts[index].settings,
      ...payload.settings,
    }

    // Update settings of account and selectedAccount
    Vue.set(state.accounts[index], "settings", newSettings)
    Vue.set(state.selectedAccount, "settings", newSettings)
  },
  [SET_ACCOUNTS](state, payload) {
    // state.accounts = [...state.accounts, ...payload]
    state.accounts = payload
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}

// Static accounts array
// {
//   id: '6de4b434-fb7a-40ed-8bab-ed2f8418ca21',
//   created_at: '2019-09-20T19:46:42.755Z',
//   updated_at: '2019-09-20T19:47:02.000Z',
//   version: 2,
//   client_code: 'interfaces',
//   name: 'Wiza, Mertz and Gibson',
//   privacy_policy:
//     'Recusandae nobis nihil iste sed omnis rerum aut sunt impedit. Qui id omnis temporibus perferendis accusamus exercitationem ipsum ex. Aut ex et eos repellendus eaque nesciunt. Aut iste possimus quis perferendis incidunt. Laudantium adipisci dolores occaecati adipisci earum esse voluptatem. Porro eaque qui ipsam rerum cupiditate laboriosam quod ut.',
//   company_information: 'Direct',
//   tagline: 'Expanded dynamic contingency',
//   settings: {
//     id: 'e6918933-cdd1-4b24-945a-13bd1a97b1d3',
//     created_at: '2019-09-20T19:46:43.828Z',
//     updated_at: '2019-09-20T19:46:43.828Z',
//     version: 1,
//     has_gate: true,
//     has_office: true,
//     rental_generate_random_gate_code: true,
//     fb_pixel_enabled: true,
//     enable_portal: true,
//     portal_enable_payments: false,
//     portal_enable_partial_payments: true,
//     portal_payment_disabled_message:
//       'Payments are currently under maintenance. Please call the office to make a payment.',
//     portal_num_prepay_months: 12,
//     enable_reservations: true,
//     reservation_num_days_in_advance: 10,
//     reservation_use_date_picker: true,
//     reservation_days_valid: 2,
//     reservation_disabled_message: 'Blah blah.',
//     enable_rentals: true,
//     rental_require_insurance: false,
//     rental_show_insurance: false,
//     rental_num_days_in_advance: 12,
//     rental_use_date_picker: true,
//     rental_show_autopay: true,
//     rental_pre_check_autopay: false,
//     rental_disabled_message:
//       'Rentals are currently under maintenance. Please call the office to make a payment.',
//     enable_reviews: true,
//     reviews_page_title: '',
//     reviews_blurb: '',
//     reviews_link_url: '',
//     reviews_meta_title: '',
//     reviews_meta_description: '',
//     enable_announcement_bar: true,
//     announcement_bar_text: '',
//     software: ''
//   },
//   address: {
//     id: '0d7a0609-6cb1-472d-a186-04b9fb9b92de',
//     created_at: '2019-09-20T19:46:46.717Z',
//     updated_at: '2019-09-20T19:46:46.717Z',
//     version: 1,
//     street_1: '050 Cheyenne Islands',
//     street_2: null,
//     state_province: 'IN',
//     city: 'Schaeferborough',
//     postal: '93156-8914',
//     country: 'US',
//     lat: '-44.1156',
//     lon: '-57.7634'
//   }
// }
