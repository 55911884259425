import { ApiFactory } from "@/utils/apis/ApiFactory"
import Vue from "vue"
const GlobalStylesApi = ApiFactory.get("globalStyles")

const state = () => ({
  available: {
    button: {
      "normal-filled": "",
      "rounded-filled": "rounded-pill",
      "squared-filled": "rounded-0",
      "normal-outline": "btn-outline-*",
      "rounded-outline": "rounded-pill btn-outline-*",
      "squared-outline": "rounded-0 btn-outline-*",
    },
  },
  selected: {
    primaryButton: {
      style: "rounded-outline",
      color: "#2E81FF",
      textColor: "#2E81FF",
      hoverTextColor: "#ffffff",
      hoverColor: "#2E81FF",
    },
    secondaryButton: {
      style: "normal-filled",
      color: "#2E81FF",
      textColor: "#FFFFFF",
      hoverTextColor: "#2E81FF",
      hoverColor: "#FFFFFF",
    },
    colors: {
      color_main: "#FFFFFF",
      color_alt: "#FFFFFF",
      color_background: "#FFFFFF",
      color_text: "#FFFFFF",
      color_heading: "#FFFFFF",
      color_link: "#FFFFFF",
      color_alert: "#FFFFFF",
      color_success: "#FFFFFF",
    },
  },
})

const getters = {
  available: (state) => state.available,
  selected: (state) => state.selected,
}

const mutations = {
  setGlobalStyle(state, payload) {
    state.selected = { ...state.selected, ...payload }
  },
}

const actions = {
  async setGlobalStyle({ rootGetters, commit }, payload) {
    const account = rootGetters["user/getSelectedAccountId"]
    try {
      await GlobalStylesApi.update(payload, account)
    } catch (e) {
      Vue.rollbar.error('Store: Error updating global styles', e)
      return e
    }
  },
  createButtonsStyle({ commit }, payload) {
    commit("setGlobalStyle", payload)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
