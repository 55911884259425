import _ from "lodash"
import { AMENITIES } from "@/utils/constants/general"
import { ApiFactory } from "@/utils/apis/ApiFactory"
const AmenityApi = ApiFactory.get("amenity")
const AmenityListApi = ApiFactory.get("amenityList")

// const STYLES = ['circle', 'color', 'filled', 'lined']
const STYLES = ["circle"]
import Vue from "vue"

// const availableAmenities = {}

// STYLES.forEach((style) => {
//   availableAmenities[style] = AMENITIES.map((amenity) => {
//     return {
//       ...amenity,
//       icon: require(`@/assets/svg/ic-${amenity.name}-${style}.svg`),
//       name: `ic-${amenity.name}-${style}`
//     }
//   })
// })

const SET_AMENITY_LISTS = "SET_AMENITY_LISTS"
const ADD_AMENITY_LIST = "ADD_AMENITY_LIST"
const UPDATE_AMENITY_LIST = "UPDATE_AMENITY_LIST"

const state = () => ({
  availableStyles: STYLES,
  amenityLists: null,
})

const getters = {
  availableStyles: (state) => state.availableStyles,
  getAmenityLists: (state) => state.amenityLists,
  getAmenityListById: (state) => (id) => {
    return state.amenityLists.find((list) => list.id === id)
  },
}

const mutations = {
  [ADD_AMENITY_LIST](state, payload) {
    state.amenityLists = [...state.amenityLists, ...payload]
  },
  [UPDATE_AMENITY_LIST](state, list) {
    const amenityLists = state.amenityLists
    const index = amenityLists.findIndex((n) => n.id === list.id)
    amenityLists[index] = list
    state.amenityLists = amenityLists
  },
  [SET_AMENITY_LISTS](state, payload) {
    state.amenityLists = payload
  },
}

const actions = {
  setAmenityList({ commit, dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        var relations = "amenities,amenities.image,amenities.image.tags"
        if (payload && payload.loadReference === true) {
          relations = ""
        }
        const { data: res } = await AmenityListApi.all(null, relations)

        // console.log(res)
        commit(SET_AMENITY_LISTS, res.data)

        return resolve(res.data)
      } catch (e) {
        Vue.rollbar.error('Store: Error setting amenity list', e)
        console.error(e)
        return reject(e)
      }
    })
  },
  createAmenityList({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const list = {}
        const { data: res } = await AmenityListApi.create(list)
        const menu = res.data

        // console.log(menu)

        commit(ADD_AMENITY_LIST, [
          {
            id: menu.id,
            name: menu.name,
            amenities: menu.amenities || [],
          },
        ])

        return resolve(menu.id)
      } catch (e) {
        Vue.rollbar.error('Store: Error creating amenity list', e)
        return reject(e)
      }
    })
  },
  saveAmenityList({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data: res } = await AmenityListApi.update(payload, null, "amenities")
        const list = res.data

        commit(UPDATE_AMENITY_LIST, list)

        return resolve(list)
      } catch (e) {
        Vue.rollbar.error('Store: Error saving amenity list', e)
        return reject(e)
      }
    })
  },
  saveAmenity({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data: res } = await AmenityApi.update(payload, null)
        const list = res.data

        commit(UPDATE_AMENITY_LIST, list)

        return resolve(list)
      } catch (e) {
        Vue.rollbar.error('Store: Error saving amenity', e)
        return reject(e)
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
