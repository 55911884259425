/**
 * Blog post state
 */
import * as uuid from "uuid/v1"
import { ApiFactory } from "@/utils/apis/ApiFactory"
const BlogApi = ApiFactory.get("blog")
import Vue from "vue"

// Mutation types
const SET_BLOGS = "SET_BLOGS"
const CLONE_POST = "CLONE_POST"
const DELETE_POST = "DELETE_POST"

const state = () => ({
  /**
   * We should probably have someone to select a specific blog for an account,
   * but for testing purposes, it's hard coded :)
   */
  selectedBlogAccount: "a522a4c0-43ef-44c3-b3b5-fec6ae78eca0",
  blogs: [],
  /**
   * Posts will be an array of Post objects.
   *
   * Post Object:
   * @example
   * {
   *  id: String
   *  banner_img: {
   *      src: String
   *      title: String
   *      alt: String
   *  }
   *  content: String
   *  date: [String, Date]
   *  date_modified: [String, Date]
   *  date_published: [String, Date]
   *  isChanges: Boolean
   *  meta: String
   *  slug: String
   *  status: String
   *  tags: Array<String>
   *  title: String
   * }
   */
  posts: [],
})

/**
 * Some sweet, canned corned getters for our blog posts.
 */
const getters = {
  /**
   * Return selected blog account ID
   */
  getSelectedBlogAccount: (state) => state.selectedBlogAccount,
  /**
   * Return all selected account's blog posts
   */
  getPosts: (state) => {
    const blogs = state.blogs
    const blogId = state.selectedBlogAccount

    if (blogs && blogs.length) {
      const index = blogs.findIndex((b) => b.id === blogId)

      if (index > -1) {
        return blogs[index].posts && blogs[index].posts.length ? blogs[index].posts : []
      }
    }

    return []
  },
}

/**
 * @todo
 * - Add action to retrieve all posts from DB
 * - Add action to find post from DB
 */
const actions = {
  /**
   * Initialize Vuex with blogs
   */
  setBlogs({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      let account = rootGetters["user/getSelectedAccountId"]
      let relations = "posts,posts.banner_image"

      if (payload && payload.account) {
        account = payload.account
      }

      if (payload && payload.relations) {
        relations += "," + payload.relations
      }

      try {
        // Make async API call and mutate store
        const response = await BlogApi.all(account, relations)
        const blog_payload = response.data.data

        // Mutate store
        if (blog_payload) {
          commit(SET_BLOGS, blog_payload)
        }
      } catch (e) {
        Vue.rollbar.error(e)
        console.log("Error with blog/setBlogs")
      }
    })
  },
  /**
   * Clone post.
   *
   * @todo We will add an api call to add cloned post.
   */
  clonePost({ commit }, payload) {
    // Create new uuid, reset status, views, and comments for cloned post
    // payload.title = payload.title + " (Clone)";
    const newIdPayload = {
      ...payload,
      id: uuid(),
      status: "draft",
      views: 0,
      comments: 0,
    }
    newIdPayload.title = newIdPayload.title + " (Clone)"
    commit(CLONE_POST, { oldId: payload.id, payload: newIdPayload })
  },
  /**
   * Delete post.
   *
   * @todo We will add an api call to delete post.
   */
  deletePost({ commit }, id) {
    commit(DELETE_POST, id)
  },
}

/**
 * @todo
 * - Add mutation to update all posts from DB
 * - Add mutation to filter post from DB???
 */
const mutations = {
  /**
   * Set initial blogs to store
   */
  [SET_BLOGS](state, payload) {
    const blogs = state.blogs
    state.blogs = [...blogs, ...payload]
  },
  /**
   * Add cloned post to Blog state
   */
  [CLONE_POST](state, { oldId, payload }) {
    // Get blog account
    const blogs = state.blogs
    const blogId = state.selectedBlogAccount
    const bIndex = blogs.findIndex((b) => b.id === blogId)

    // Get post index
    const blog = blogs[bIndex]
    const posts = blog.posts
    const pIndex = posts.findIndex((p) => p.id === oldId)
    posts.splice(pIndex + 1, 0, payload)

    state.blogs.splice(bIndex, 1, blog)
  },
  /**
   * Remove deleted the post from the Blog state
   */
  [DELETE_POST](state, id) {
    // Get blog account
    const blogs = state.blogs
    const blogId = state.selectedBlogAccount
    const bIndex = blogs.findIndex((b) => b.id === blogId)

    // Get post index
    const blog = blogs[bIndex]
    const posts = blog.posts
    const pIndex = posts.findIndex((p) => p.id === id)
    posts.splice(pIndex, 1)

    state.blogs.splice(bIndex, 1, blog)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
