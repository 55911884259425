import { startOfMonth, startOfDay, subDays, addDays } from "date-fns"
import { MockDataList } from "@/dashboard/charts/chart-config"
const SET_DATE_FROM = "SET_DATE_FROM"
const SET_DATE_TO = "SET_DATE_TO"
const SET_IS_ADMIN_CHARTS = "SET_IS_ADMIN_CHARTS"
const TOGGLE_UPDATE_DATES = "TOGGLE_UPDATE_DATES"
const SET_STATS = "SET_STATS"
const TOGGLE_RENTAL_CHART_WIDTH = "TOGGLE_RENTAL_CHART_WIDTH"
const SET_UNITS_RENTED = "SET_UNITS_RENTED"
const SET_UNITS_RESERVED = "SET_UNITS_RESERVED"
const SET_FILTERED_LOCATIONS = "SET_FILTERED_LOCATIONS"
const SET_FILTERED_CLIENTS = "SET_FILTERED_CLIENTS"
const UPDATE_QUERY_STATUS = "UPDATE_QUERY_STATUS"
const UPDATE_QUERY_DATA = "UPDATE_QUERY_DATA"
const UPDATE_QUERY_TOGGLE = "UPDATE_QUERY_TOGGLE"
const SET_PARENT_DASHBOARD = "SET_PARENT_DASHBOARD"
import Vue from "vue"
import { generateApiUrl } from "@/utils/helpers"
var md5 = require("blueimp-md5")
import _ from "lodash"
import { EventBus } from "@/utils/event-bus"
import { ApiFactory } from "@/utils/apis/ApiFactory"
const EventApi = ApiFactory.get("event")

export const state = () => ({
  dateFrom: startOfMonth(new Date()),
  // addDays(startOfMonth(new Date()), 5),
  dateTo: startOfDay(new Date()),
  updateDates: false,
  isAdminCharts: false,
  parentDashboard: null,
  stats: [],
  unitsRentedStats: {},
  unitsReservedStats: {},
  salesIncome: MockDataList,
  availableUnits: [
    {
      storage: "San Francisco",
      total_units: 100,
      visitors: {
        percentage: 0.75,
        progressColor: "gradient-success",
      },
    },
    {
      storage: "Los Angeles",
      total_units: 150,
      visitors: {
        percentage: 0.35,
        progressColor: "gradient-info",
      },
    },
    {
      storage: "Knoxville",
      total_units: 75,
      visitors: {
        percentage: 0.25,
        progressColor: "gradient-danger",
      },
    },
    {
      storage: "Austin",
      total_units: 75,
      visitors: {
        percentage: 0.45,
        progressColor: "gradient-warning",
      },
    },
    {
      storage: "St. Louis",
      total_units: 75,
      visitors: {
        percentage: 0.85,
        progressColor: "gradient-primary",
      },
    },
  ],
  rentalChartWidth: "small",
  filteredLocations: {},
  filteredClients: [],
  queryStatus: {},
  queryData: {},
  queryToggle: false,
})

export const getters = {
  getQueryToggle: (state) => {
    return state.queryToggle
  },
  getParentDashboard: (state) => {
    return state.parentDashboard
  },
  getQueryData: (state) => (id) => {
    return state.queryData[id]
  },
  getQueryStatus: (state) => (id) => {
    return state.queryStatus[id]
  },
  getAllQueryStatus: (state) => {
    return state.queryStatus
  },
  getFilteredLocations(state) {
    return state.filteredLocations
  },
  getFilteredClients(state) {
    return state.filteredClients
  },
  getUpdateDates(state) {
    return state.updateDates
  },
  getIsAdminCharts(state) {
    return state.isAdminCharts
  },
  getRentalChartWidth(state) {
    return state.rentalChartWidth
  },
  getDateFrom(state) {
    return state.dateFrom
  },
  getDateTo(state) {
    return state.dateTo
  },
  getStats(state) {
    return state.stats
  },
  getSalesIncome(state) {
    return state.salesIncome
  },
  getUnitsRentedStats(state) {
    return state.unitsRentedStats
  },
  getUnitsReservedStats(state) {
    return state.unitsReservedStats
  },
  getAvailableUnits(state) {
    return state.availableUnits
  },
}

export const actions = {
  /**
   * Initialize Vuex with blogs
   */
  setFilteredClients({ commit, rootGetters }, payload) {
    commit(SET_FILTERED_CLIENTS, payload)
  },
  toggleUpdateDates({ commit, rootGetters }, payload) {
    commit(TOGGLE_UPDATE_DATES, payload)
  },
  setParentDashboard({ commit, rootGetters }, payload) {
    commit(SET_PARENT_DASHBOARD, payload)
  },
  setFilteredLocations({ commit, rootGetters }, payload) {
    commit(SET_FILTERED_LOCATIONS, payload)
  },
  setDateFrom({ commit, rootGetters }, payload) {
    commit(SET_DATE_FROM, payload)
  },
  toggleRentalChartWidth({ commit, rootGetters }, payload) {
    commit(TOGGLE_RENTAL_CHART_WIDTH, payload)
  },
  setDateTo({ commit, rootGetters }, payload) {
    commit(SET_DATE_TO, payload)
  },
  setIsAdminCharts({ commit, rootGetters }, payload) {
    commit(SET_IS_ADMIN_CHARTS, payload)
  },
  setRentalStats({ commit, rootGetters, state }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let filteredLocations = []
        if (payload) {
          filteredLocations.push(payload)
        } else {
          filteredLocations = state.filteredLocations
        }
        var accountId = rootGetters["user/getSelectedAccountId"]
        if (state.isAdminCharts === true) {
          accountId = null
        }
        const { data: res } = await EventApi.getRentalsChart(accountId, state.dateFrom, state.dateTo, "rentals", filteredLocations)
        commit(SET_UNITS_RENTED, res)
        return resolve()
      } catch (e) {
        Vue.rollbar.error('Store: Error setting rental stats', e)
        return reject()
      }
    })
  },
  setReservationStats({ commit, rootGetters, state }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let filteredLocations = []
        if (payload) {
          filteredLocations.push(payload)
        } else {
          filteredLocations = state.filteredLocations
        }
        var accountId = rootGetters["user/getSelectedAccountId"]
        if (state.isAdminCharts === true) {
          accountId = null
        }
        const { data: res } = await EventApi.getRentalsChart(accountId, state.dateFrom, state.dateTo, "reservations", filteredLocations)
        commit(SET_UNITS_RESERVED, res)
        return resolve()
      } catch (e) {
        Vue.rollbar.error('Store: Error setting reservation stats', e)
        return reject()
      }
    })
  },
  setStats({ commit, rootGetters, state }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let filteredLocations = []
        if (payload) {
          filteredLocations.push(payload)
        } else {
          filteredLocations = state.filteredLocations
        }
        var accountId = rootGetters["user/getSelectedAccountId"]
        if (state.isAdminCharts === true) {
          accountId = null
        }
        const { data: res } = await EventApi.getStats(accountId, state.dateFrom, state.dateTo, filteredLocations)
        commit(SET_STATS, res)
        return resolve()
      } catch (e) {
        Vue.rollbar.error('Store: Error setting stats', e)
        return reject()
      }
    })
  },
  runQuery({ commit, rootGetters, state }, payload) {
    return new Promise(async (resolve, reject) => {
      // filterLocationsVuex: "leads/filterLocations",
      let location = state.filteredLocations
      let rhsClientCodes = rootGetters["accounts/getRhsClientCodes"]
      let account = rootGetters["accounts/getSelectedAccount"]
      var location_ids = location && location.id ? [location.id] : null
      let isRhs = _.findIndex(rhsClientCodes, (o) => { return o == account.client_code })
      if (isRhs != -1) {
        location_ids = null
      }
      var body = {
        startDate: state.dateFrom,
        endDate: state.dateTo,
        // client_codes: [],
        client_codes: state.filteredClients,
        location_ids: location_ids,
        variables: payload.query_vars ? payload.query_vars : {},
        query_id: payload.query_id,
      }
      // if (payload.query_vars) console.log('payload.query_vars: ', payload.query_vars)
      var hash = md5(JSON.stringify(body))
      body.force_update = payload.force_update
      var hasExpired = false
      const item = Vue.$vlf.getItem(hash)

      if (item.expires < Date.now()) {
        Vue.$vlf.removeItem(hash)
        hasExpired = true
      }

      if (hasExpired || body.force_update === true || !state.queryStatus[hash] || state.queryStatus[hash].status != "loaded") {
        commit(UPDATE_QUERY_STATUS, { query_id: payload.query_id, status: "started", hash: hash })
        resolve({ status: "started", hash })
        EventBus.$emit("query_started", hash)

        saveDebounced({ ...payload, hash, body, commit, rootGetters })
      } else {
        // console.log('runQuery -> state.queryStatus[hash]', state.queryStatus[hash])
        resolve({ status: state.queryStatus[hash].status, hash })
        EventBus.$emit("query_started", hash)
        setTimeout(() => {
          EventBus.$emit("query_finished", hash)
        })
      }
    })
  },
}

async function save(payload) {
  // run query
  try {
    const url = generateApiUrl(`query/${ payload.query_id }/results`)
    var user = payload.rootGetters["user/getUser"]

    let { data: res } = await axios.post(url, payload.body, { headers: { "User-ID": user.id } })

    if (res.success === true) {
      await Vue.$vlf.setItem(payload.hash, res)
      EventBus.$emit("query_finished", payload.hash)
      payload.commit(UPDATE_QUERY_STATUS, { query_id: payload.query_id, status: "finished", hash: payload.hash })
    } else {
      EventBus.$emit("query_failed", payload.hash)
    }
  } catch (e) {
    EventBus.$emit("query_failed", payload.hash)
  }
}

var saveDebounced = _.wrap(
  _.memoize(function () {
    return _.debounce(save, 100)
  }, _.property("hash")),
  function (func, payload) {
    return func(payload)(payload)
  }
)

export const mutations = {
  resetCharts(state, payload) {
    state.stats = []
    state.filteredLocations = []
    state.rentalChartWidth = "small"
    state.unitsRentedStats = {}
    state.unitsReservedStats = {}
  },
  [UPDATE_QUERY_STATUS](state, payload) {
    state.queryStatus[payload.hash] = { status: payload.status, hash: payload.hash }
  },
  [UPDATE_QUERY_DATA](state, payload) {
    state.queryData[payload.hash] = payload.data
  },
  [UPDATE_QUERY_TOGGLE](state, payload) {
    state.queryToggle = !state.queryToggle
  },
  [SET_FILTERED_CLIENTS](state, payload) {
    state.filteredClients = payload
  },
  [SET_FILTERED_LOCATIONS](state, payload) {
    state.filteredLocations = payload
  },
  [SET_UNITS_RENTED](state, payload) {
    state.unitsRentedStats = payload
  },
  [SET_UNITS_RESERVED](state, payload) {
    state.unitsReservedStats = payload
  },
  [SET_IS_ADMIN_CHARTS](state, payload) {
    state.isAdminCharts = payload
  },
  [SET_PARENT_DASHBOARD](state, payload) {
    state.parentDashboard = payload
  },
  [TOGGLE_RENTAL_CHART_WIDTH](state, payload) {
    if (state.rentalChartWidth === "small") {
      state.rentalChartWidth = "large"
    } else {
      state.rentalChartWidth = "small"
    }
  },
  [TOGGLE_UPDATE_DATES](state, payload) {
    state.updateDates = !state.updateDates
  },
  [SET_DATE_FROM](state, payload) {
    state.dateFrom = payload
  },
  [SET_DATE_TO](state, payload) {
    state.dateTo = payload
  },
  [SET_STATS](state, payload) {
    state.stats = payload
  },
}
