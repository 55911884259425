/**
 * Editor Sidebar state
 */
import * as uuid from "uuid/v1"
import * as _ from "lodash"
import { findById, recursivelySort } from "@/dashboard/utils/site-builder"
import { ApiFactory } from "@/utils/apis/ApiFactory"
import Vue from "vue"
const ComponentSchemaApi = ApiFactory.get("componentSchema")
const PageApi = ApiFactory.get("page")
import axios from "axios"
import { generateApiUrl, buildNewPropsFromSchema, updatePropsFromSchema, buildSchemas, updateSchemas } from "@/utils/helpers"

// Editor Mutation types
const DISPLAY_EDITOR_SIDEBAR = "DISPLAY_EDITOR_SIDEBAR"
const TOGGLE_EDITOR_SIDEBAR = "TOGGLE_EDITOR_SIDEBAR"
const MOUSE_ENTER_EDITOR_SIDEBAR = "MOUSE_ENTER_EDITOR_SIDEBAR"
const MOUSE_LEAVE_EDITOR_SIDEBAR = "MOUSE_LEAVE_EDITOR_SIDEBAR"

// Sidebar mutations
const APPLY_FORM_SETTINGS = "APPLY_FORM_SETTINGS"
const APPLY_FOOTER_FORM_SETTINGS = "APPLY_FOOTER_FORM_SETTINGS"
const APPLY_NAVBAR_FORM_SETTINGS = "APPLY_NAVBAR_FORM_SETTINGS"
const UPDATE_FORM_SETTINGS = "UPDATE_FORM_SETTINGS"
const SELECT_FORM_SETTINGS = "SELECT_FORM_SETTINGS"
const SELECT_FOOTER_FORM_SETTINGS = "SELECT_FOOTER_FORM_SETTINGS"
const SELECT_NAVBAR_FORM_SETTINGS = "SELECT_NAVBAR_FORM_SETTINGS"

// Page Types
const SET_SELECTED_PAGE = "SET_SELECTED_PAGE"
const SET_LOCATION_PAGES = "SET_LOCATION_PAGES"
const SET_EDITOR_ADD = "SET_EDITOR_ADD"
const SELECT_PAGE_SETTINGS = "SELECT_PAGE_SETTINGS"
const SAVE_PAGE_SETTINGS = "SAVE_PAGE_SETTINGS"
const UPDATE_PAGE_SETTINGS = "UPDATE_PAGE_SETTINGS"
const UPDATE_PAGE_WITH_FORM_SETTINGS = "UPDATE_PAGE_WITH_FORM_SETTINGS"
const UPDATE_EDITOR_HAS_CHANGES = "UPDATE_EDITOR_HAS_CHANGES"

// Component Mutation types
const SET_COMPONENTS = "SET_COMPONENTS"

const state = () => ({
  showEditorSidebar: true,
  toggleEditorSidebar: false,
  breakpoint: 1200,
  formSettings: null,
  footerFormSettings: null,
  navbarFormSettings: null,
  components: [],
  pageSettings: null,
  selectedPage: null,
  locationPages: null,
  isEditorAddPage: false,
  editorHasChanges: false
})

/**
 * Getters for Editor Sidebar
 */
const getters = {
  getShowEditorSidebar: (state) => state.showEditorSidebar,
  getToggleEditorSidebar: (state) => state.toggleEditorSidebar,
  getEditorSidebarBreakpoint: (state) => state.breakpoint,
  getPageSettings: (state) => state.pageSettings,
  getSelectedPage: (state) => state.selectedPage,
  getLocationPages: (state) => state.locationPages,
  getFormSettings: (state) => state.formSettings,
  getFooterFormSettings: (state) => state.footerFormSettings,
  getNavbarFormSettings: (state) => state.navbarFormSettings,
  getComponents: (state) => state.components,
  getIsEditorAddPage: (state) => state.isEditorAddPage,
  getEditorHasChanges: (state) => state.editorHasChanges,
  getNavbarComponentSchemas: (state) => state.components.filter((c) => c.name.includes("Nav")),
  getFooterComponentSchemas: (state) => state.components.filter((c) => c.name.includes("Footer"))
}

/**
 * Editor Sidebar actions
 */
const actions = {
  /**
   * Display Editor Sidebar
   */
  displayEditorSidebar({ commit }, payload) {
    commit(DISPLAY_EDITOR_SIDEBAR, payload)
  },
  /**
   * Toggle Editor Sidebar
   */
  toggleEditorSidebar({ commit }) {
    commit(TOGGLE_EDITOR_SIDEBAR)
  },
  /**
   * Mouse Enter Editor Sidebar
   */
  onMouseEnter({ commit }) {
    commit(MOUSE_ENTER_EDITOR_SIDEBAR)
  },
  /**
   * Mouse Enter Editor Sidebar
   */
  onMouseLeave({ commit }) {
    commit(MOUSE_LEAVE_EDITOR_SIDEBAR)
  },
  /**
   * Update Page Settings
   *
   * When a user navigates to the website editor, update
   * the page settings with the current page being edited.
   */
  selectPageSettings({ commit }, payload) {
    commit(SELECT_PAGE_SETTINGS, payload)
  },
  /**
   * Save Page settings to database.
   *
   * Need to updates the pages array in the Page store with any new saved pages.
   */
  savePageSettings({ dispatch, commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        // const accountId = rootGetters['user/getSelectedAccountId']
        const website = rootGetters["website/getSelectedWebsite"]

        // Fail safe, if ID exists, then we should be updating
        // an existing object, not creating a new object
        if (payload.id && !payload.is_new_page) {
          return resolve(await dispatch("updatePageSettings", payload))
        }

        // If a page setting doesn't include a website_id, add one
        if (!payload.website) {
          payload.website = { id: website.id }
        }

        const { data: response } = await PageApi.create(payload, null)
        const response_data = response.data

        // We re-sort the save response because it may return in the incorrect order.
        const sortedResponse = _.cloneDeep(response_data)
        recursivelySort(sortedResponse)

        dispatch("page/addPageToStore", sortedResponse, { root: true })
        // Update the page settings on save
        commit(SAVE_PAGE_SETTINGS, sortedResponse)

        // Return success response and create toast
        return resolve({ success: true, page: sortedResponse })
      } catch (error) {
        Vue.rollbar.error('Store: Error saving page settings', error)
        return reject(error)
      }
    })
  },
  /**
   * Save new location page to database.
   *
   * Need to update the pages array in the Page store with any new saved pages.
   */
  saveLocationPage({ dispatch, commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const website = rootGetters["website/getSelectedWebsite"]
        const accountId = rootGetters["user/getSelectedAccountId"]

        // Fail safe, if ID exists, then we should be updating
        // an existing object, not creating a new object
        if (payload.id && !payload.is_new_page) {
          return resolve(await dispatch("updatePageSettings", payload))
        }


        // Remove the last_edited_by field
        if (payload && payload.last_edited_by) {
          delete payload.last_edited_by
        }

        const { data: response } = await PageApi.createLocationPage(payload, accountId, website.id)
        const response_data = response.data

        // We resort the save response because it may return in the incorrect order.
        const sortedResponse = _.cloneDeep(response_data[0])

        // Parse sections object
        try {
          let res = JSON.parse(sortedResponse.sections_object)
          sortedResponse.sections_object = res
        } catch (error) { }

        // Sort the page resposne
        recursivelySort(sortedResponse)

        // Update the page settings on save
        dispatch("page/addPageToStore", sortedResponse, { root: true })
        commit(SAVE_PAGE_SETTINGS, sortedResponse)

        // Return success response and create toast
        return resolve({ success: true, page: sortedResponse })
      } catch (error) {
        Vue.rollbar.error('Store: Error saving location page', error)
        return reject(error)
      }
    })
  },
  /**
   * Update selected page settings in the database, and then
   * update the page settings in the store with the api response.
   */
  updatePageSettings({ dispatch, commit, rootGetters, state }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const website = rootGetters["website/getSelectedWebsite"]

        const relArray = [
          "sections",
          "style_option",
          "theme",
          "location",
        ]

        const relations = relArray.join(",")

        // If a page setting doesn't include a website_id, add one
        if (!payload.website) {
          payload.website = { id: website.id }
        }

        // Update page after deletes
        const { data: response } = await PageApi.update(payload, null, relations)
        const response_data = response.data

        // We resort the save response because it may return in the incorrect order.
        const sortedResponse = _.cloneDeep(response_data)
        recursivelySort(sortedResponse)

        // Update the page settings with the updated database response
        dispatch("page/updatePage", sortedResponse, { root: true })
        commit(UPDATE_PAGE_SETTINGS, sortedResponse)

        // Return success response and create toast
        return resolve({ success: true, page: sortedResponse })
      } catch (error) {
        // Return error and create toast
        Vue.rollbar.error('Store: Error updating page settings', error)
        return reject(error)
      }
    })
  },
  /**
   * Update Form Settings
   *
   * When a user clicks on a component placeholder, we pass
   * the new form settings into the formSettings state.
   */
  selectFormSettings({ commit, state }, payload) {
    const formSettings = _.cloneDeep(payload)
    let schemaFromDB = null

    if (formSettings && formSettings.schema) {
      schemaFromDB = state.components.find(val => val.id == formSettings.schema.id)
    }

    if (formSettings && formSettings.props) {
      // Flatten the props schema objects
      const currentSchemas = formSettings.props.flatMap((s) => s.schema)

      // items removed
      const removedSchemas = _.differenceBy(currentSchemas, (schemaFromDB ? schemaFromDB.prop_schemas : formSettings.schema.prop_schemas), "id")

      // items added/changed
      const addedOrUpdatedSchemas = _.differenceWith((schemaFromDB ? schemaFromDB.prop_schemas : formSettings.schema.prop_schemas), currentSchemas, _.isEqual)

      const actuallyRemovedProps = []
      const actuallyRemovedSchemas = []
      const removedMissedProps = []
      const removedMissedSchemas = []

      if (removedSchemas.length > 0 || addedOrUpdatedSchemas.length > 0) {
        console.log("currentSchemas", currentSchemas)
      }

      // remove deprecated props & prop schemas
      if (removedSchemas.length > 0) {
        console.log("removedSchemas", removedSchemas)
        for (let removedSchema of removedSchemas) {
          const propIndex = formSettings.props.findIndex((p) => p.schema.id == removedSchema.id)
          const schemaIndex = formSettings.schema.prop_schemas.findIndex((s) => s.id == removedSchema.id)

          // remove the prop from the component that should no longer be there
          if (propIndex > -1) {
            formSettings.props.splice(propIndex, 1)
            actuallyRemovedProps.push(removedSchema)
          }
          else {
            removedMissedProps.push(removedSchema)
          }

          // remove the prop_schema from the component that should no longer be there as well
          if (schemaIndex > -1) {
            formSettings.props.splice(propIndex, 1)
            actuallyRemovedSchemas.push(removedSchema)
          }
          else {
            removedMissedSchemas.push(removedSchema)
          }
        }

        console.info(`removed ${ actuallyRemovedProps.length } props from ${ formSettings.name }: ${ actuallyRemovedProps.map(s => s.label).join(", ") }`)
        console.info(`removed ${ actuallyRemovedSchemas.length } prop schemas from ${ formSettings.name }: ${ actuallyRemovedSchemas.map(s => s.label).join(", ") }`)

        if (removedMissedProps.length > 0) {
          console.warn(`failed to remove ${ removedMissedProps.length } props from ${ formSettings.name }: ${ removedMissedProps.map(s => s.label).join(", ") }`)
        }
        if (removedMissedSchemas.length > 0) {
          console.warn(`failed to remove ${ removedMissedSchemas.length } props from ${ formSettings.name }: ${ removedMissedSchemas.map(s => s.label).join(", ") }`)
        }
      }

      if (addedOrUpdatedSchemas.length > 0) {
        console.log("addedOrUpdatedSchemas", addedOrUpdatedSchemas)
        const newSchemas = _.differenceBy((schemaFromDB ? schemaFromDB.prop_schemas : formSettings.schema.prop_schemas), currentSchemas, "id")
        const updatedSchemas = _.differenceBy(addedOrUpdatedSchemas, newSchemas, "id")

        if (updatedSchemas.length > 0) {
          // update props & prop schemas
          formSettings.props = updatePropsFromSchema(updatedSchemas, formSettings.props)
          formSettings.schema.prop_schemas = updateSchemas(updatedSchemas, formSettings.schema.prop_schemas)
          if (updatedSchemas.length > 1) {
            console.info(`updated ${ updatedSchemas.length } props & prop schemas: ${ updatedSchemas.map(s => s.label).join(", ") }`)
          }
          else {
            console.info(`updated ${ updatedSchemas.length } prop & prop schema: ${ updatedSchemas.map(s => s.label).join(", ") }`)
          }
        }

        if (newSchemas.length > 0) {
          // add new props & prop schemas
          const newProps = buildNewPropsFromSchema(newSchemas)
          formSettings.props = formSettings.props.concat(newProps)

          const newPropSchemas = buildSchemas(newSchemas)
          formSettings.schema.prop_schemas = formSettings.schema.prop_schemas.concat(newPropSchemas)

          if (newSchemas.length > 1) {
            console.info(`added ${ newSchemas.length } new props & prop schemas: ${ newSchemas.map(s => s.label).join(", ") }`)
          }
          else {
            console.info(`added ${ newSchemas.length } new prop & prop schema: ${ newSchemas.map(s => s.label).join(", ") }`)
          }
        }
      }
    }

    commit(SELECT_FORM_SETTINGS, formSettings)
  },
  /**
   * Update Form Settings
   *
   * When a user clicks on a component placeholder, we pass
   * the new form settings into the formSettings state.
   */
  selectFooterFormSettings({ commit, state }, payload) {
    commit(SELECT_FOOTER_FORM_SETTINGS, payload)
  },
  /**
   * Update Form Settings
   *
   * When a user clicks on a component placeholder, we pass
   * the new form settings into the formSettings state.
   */
  selectNavbarFormSettings({ commit, state }, payload) {
    commit(SELECT_NAVBAR_FORM_SETTINGS, payload)
  },
  /**
   * Update page settings
   */
  applyPageSettings({ commit }, payload) {
    // Update settings in page
    commit(UPDATE_PAGE_SETTINGS, payload)
  },
  /**
   * Update form settings
   *
   * @todo Perform an async API function call then mutate the formSettings state
   */
  applyFormSettings({ dispatch, state, commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        // Spread "metadata" into payload object
        // This is done because metadata isn't an actual field in db
        // It's being used to categorize form inputs in the EditorSidebar
        let flattenedPayload = payload
        if (payload.metadata) {
          flattenedPayload = Object.assign(flattenedPayload, payload.metadata)
          // Delete metadata object in case a metadata field
          // has an off-chance of being written to db
          delete flattenedPayload.metadata
        }

        // Update settings in page
        if (state.pageSettings && !payload.isNavigation) {
          commit(UPDATE_PAGE_WITH_FORM_SETTINGS, flattenedPayload)
        }

        // Update the Vuex settings
        commit(APPLY_FORM_SETTINGS, flattenedPayload)

        return resolve({ success: true })
      } catch (error) {
        Vue.rollbar.warning('Store: Error applying form settings', error)
        return reject(error)
      }
    })
  },
  /**
   * Update form settings
   *
   * @todo Perform an async API function call then mutate the formSettings state
   */
  applyFooterFormSettings({ dispatch, state, commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        // Spread "metadata" into payload object
        // This is done because metadata isn't an actual field in db
        // It's being used to categorize form inputs in the EditorSidebar
        let flattenedPayload = payload
        if (payload.metadata) {
          flattenedPayload = Object.assign(flattenedPayload, payload.metadata)
          // Delete metadata object in case a metadata field
          // has an off-chance of being written to db
          delete flattenedPayload.metadata
        }

        // Update the Vuex settings
        commit(APPLY_FOOTER_FORM_SETTINGS, flattenedPayload)

        return resolve({ success: true })
      } catch (error) {
        Vue.rollbar.warning('Store: Error applying footer form settings', error)
        return reject(error)
      }
    })
  },
  /**
   * Update form settings
   *
   * @todo Perform an async API function call then mutate the formSettings state
   */
  setNavbarFormSettings({ dispatch, state, commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        return resolve({ success: true })
      } catch (error) {
        Vue.rollbar.error(error)
        return reject(error)
      }
    })
  },
  applyNavbarFormSettings({ dispatch, state, commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        // Spread "metadata" into payload object
        // This is done because metadata isn't an actual field in db
        // It's being used to categorize form inputs in the EditorSidebar
        let flattenedPayload = payload
        if (payload.metadata) {
          flattenedPayload = Object.assign(flattenedPayload, payload.metadata)
          // Delete metadata object in case a metadata field
          // has an off-chance of being written to db
          delete flattenedPayload.metadata
        }

        // Update the Vuex settings
        commit(APPLY_NAVBAR_FORM_SETTINGS, flattenedPayload)

        return resolve({ success: true })
      } catch (error) {
        Vue.rollbar.warning('Store: Error applying navbar form settings', error)
        return reject(error)
      }
    })
  },
  /**
   * Set selectec page. We use this to watch if a selected page
   * is a location page in the website editor.
   *
   * If it's a location page, we need to query pages from database.
   */
  setSelectedPage({ commit, dispatch }, payload) {
    commit(SET_SELECTED_PAGE, payload)

    // Query all location pages
    if (payload && payload.is_location) {
      return dispatch("queryAndSetLocationPages", payload)
    }
  },
  /**
   * Query and set location pages
   */
  queryAndSetLocationPages({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const pageRoute = payload.route
        // if(pageRoute == '') {
        //   pageRoute = 'home'
        // }

        const selectedAccountId = rootGetters["user/getSelectedAccountId"]

        // Query all pages that have same route
        // const where = JSON.stringify({ route: pageRoute, is_location: true })
        // const { data: response } = await PageApi.all(null, "location", where)
        let url = generateApiUrl(`${ selectedAccountId }/location-page/by-route`)
        let { data: res } = await axios.post(url, { pageRoute: pageRoute })
        const pages = res.data

        // We need to filter pages based on the page's location's account_id
        // so we don't get location pages across account levels
        // const filteredLocationPages = pages.filter((page) => {
        //   if (page && page.location && page.location.account_id === selectedAccountId) {
        //     return true
        //   }
        // })

        // Set location pages
        commit(SET_LOCATION_PAGES, pages)

        return resolve({ success: true })
      } catch (error) {
        Vue.rollbar.error('Store: Error setting location pages', error)
        return reject(error)
      }
    })
  },
  /**
   * Set location pages
   */
  setLocationPages({ commit }, payload) {
    commit(SET_LOCATION_PAGES, payload)
  },
  /**
   * Set website components
   */
  setComponents({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        // Get from database
        const component_response = await ComponentSchemaApi.all(null, "prop_schemas,prop_schemas.options,prop_schemas.children,prop_schemas.children.children,prop_schemas.children.options,image", null, 10000000000000)
        const schema_data = component_response.data.data

        commit(SET_COMPONENTS, schema_data)

        // Resolve
        return resolve({ success: true })
      } catch (error) {
        Vue.rollbar.error('Store: Error setting components', error)
        return reject(error)
      }
    })
  },
  /**
   * Set whether the editor is on an add page or not
   */
  setEditorAdd({ commit }, payload) {
    commit(SET_EDITOR_ADD, payload)
  },
}

/**
 * Editor Sidebar Mutations
 */
const mutations = {
  resetEditorSidebar(state, payload) {
    state.showEditorSidebar = true
    state.toggleEditorSidebar = false
    state.isEditorAddPage = false
    state.breakpoint = 1200
    state.formSettings = null
    state.footerFormSettings = null
    state.navbarFormSettings = null
    state.pageSettings = null
    state.selectedPage = null
    state.locationPages = null
    state.components = []
  },
  /**
   * Hide or Show Editor Sidebar
   */
  [DISPLAY_EDITOR_SIDEBAR](state, payload) {
    if (window.innerWidth > state.breakpoint) {
      return
    }

    // Update Editor Sidebar state
    state.showEditorSidebar = payload

    // Get body class names
    const docClasses = document.body.classList

    // Add or remove global sidenav classes
    if (payload) {
      docClasses.add("g-sidenav-pinned")
      docClasses.add("g-sidenav-show")
      docClasses.remove("g-sidenav-hidden")
    } else {
      docClasses.add("g-sidenav-hidden")
      docClasses.remove("g-sidenav-pinned")
    }
  },
  /**
   * Toggle Editor Sidebar
   */
  [TOGGLE_EDITOR_SIDEBAR](state) {
    state.toggleEditorSidebar = !state.toggleEditorSidebar
    const docClasses = document.body.classList

    // Add and remove global sidenav classes
    if (state.toggleEditorSidebar) {
      docClasses.add("g-sidenav-hidden")
      docClasses.remove("g-sidenav-pinned")
    } else {
      docClasses.add("g-sidenav-pinned")
      docClasses.remove("g-sidenav-hidden")
    }
  },
  /**
   * Mouse Enter Editor Sidebar
   */
  [MOUSE_ENTER_EDITOR_SIDEBAR](state) {
    // Add and remove global sidenav classes
    if (state.toggleEditorSidebar) {
      document.body.classList.add("g-sidenav-show")
      document.body.classList.remove("g-sidenav-hidden")
    }
  },
  /**
   * Mouse Leave Editor Sidebar
   */
  [MOUSE_LEAVE_EDITOR_SIDEBAR](state) {
    // Add and remove global sidenav classes
    if (state.toggleEditorSidebar) {
      const docClasses = document.body.classList
      docClasses.remove("g-sidenav-show")
      docClasses.add("g-sidenav-hide")
      setTimeout(() => {
        docClasses.remove("g-sidenav-hide")
        docClasses.add("g-sidenav-hidden")
      }, 300)
    }
  },
  /**
   * Mutate Page Settings with current page being edited
   */
  [SELECT_PAGE_SETTINGS](state, payload) {
    state.pageSettings = { ...payload }
  },
  /**
   * Mutate pageSettings with new page
   */
  [SAVE_PAGE_SETTINGS](state, payload) {
    let page
    if (state.pageSettings && !_.isEmpty(state.pageSettings)) {
      page = _.cloneDeep(state.pageSettings)
    } else {
      page = {}
    }

    // Spread "metadata" into payload object
    // This is done because metadata isn't an actual field in db
    // It's being used to categorize form inputs in the EditorSidebar
    let flattenedPayload = payload
    if (payload.metadata) {
      flattenedPayload = Object.assign(flattenedPayload, payload.metadata)
      // Delete metadata object in case a metadata field
      // has an off-chance of being written to db
      delete flattenedPayload.metadata
    }

    // Find the page object to merge new changes into
    const id = payload.id
    let foundObj = findById(page, id)

    if (!foundObj || _.isEmpty(foundObj)) {
      foundObj = {}
    }

    // Merge updates
    foundObj = Object.assign(foundObj, flattenedPayload)

    // Mutate store
    state.pageSettings = { ...state.pageSettings, ...page }
  },
  /**
   * Mutate Page Settings with updated settings
   */
  [UPDATE_PAGE_SETTINGS](state, payload) {
    const page = _.cloneDeep(state.pageSettings)
    // Spread "metadata" into payload object
    // This is done because metadata isn't an actual field in db
    // It's being used to categorize form inputs in the EditorSidebar
    let flattenedPayload = payload
    if (payload.metadata) {
      flattenedPayload = Object.assign(flattenedPayload, payload.metadata)
      // Delete metadata object in case a metadata field
      // has an off-chance of being written to db
      delete flattenedPayload.metadata
    }

    // Find the page object to merge new changes into
    const id = payload.id
    let foundObj = findById(page, id)

    // Merge updates
    foundObj = Object.assign(foundObj, flattenedPayload)

    // Mutate store
    state.pageSettings = { ...state.pageSettings, ...page }
  },
  /**
   * Mutate Page Settings with updated settings
   */
  [UPDATE_PAGE_WITH_FORM_SETTINGS](state, payload) {
    const page = _.cloneDeep(state.pageSettings)
    const omitKeys = ["sections", "rows", "column_widths", "props", "prop_schemas"]
    const clonedPayload = _.omit(_.cloneDeep(payload), omitKeys)

    // Spread "metadata" into payload object
    // This is done because metadata isn't an actual field in db
    // It's being used to categorize form inputs in the EditorSidebar
    // let flattenedPayload = _.omit(payload, omitKeys)
    // if (payload.metadata) {
    //   flattenedPayload = Object.assign(flattenedPayload, payload.metadata)
    //   // Delete metadata object in case a metadata field
    //   // has an off-chance of being written to db
    //   delete flattenedPayload.metadata
    // }

    // Find the page object to merge new changes into
    const id = clonedPayload.id
    const foundObj = findById(page, id)

    // Merge updates
    const mergedObj = Object.assign(foundObj, clonedPayload)

    // Mutate store
    state.pageSettings = { ...state.pageSettings, ...page }
  },
  /**
   * Mutate Form Settings from component placeholder edit click
   */
  [SELECT_FORM_SETTINGS](state, payload) {
    // Update the formSettings
    // const formSettings = state.formSettings
    if (payload) {
      state.formSettings = { ...payload }
    } else {
      state.formSettings = null
    }
  },
  /**
   * Mutate Form Settings from component placeholder edit click
   */
  [SELECT_FOOTER_FORM_SETTINGS](state, payload) {
    // Update the formSettings
    // const formSettings = state.formSettings
    if (payload) {
      state.footerFormSettings = { ...payload }
    } else {
      state.footerFormSettings = null
    }
  },
  /**
   * Mutate Form Settings from component placeholder edit click
   */
  [SELECT_NAVBAR_FORM_SETTINGS](state, payload) {
    // Update the formSettings
    // const formSettings = state.formSettings
    if (payload) {
      state.navbarFormSettings = { ...payload }
    } else {
      state.navbarFormSettings = null
    }
  },
  /**
   * Mutate Form Settings from saved form
   */
  [APPLY_FORM_SETTINGS](state, payload) {
    // Update the formSettings
    const formSettings = state.formSettings
    state.formSettings = { ...formSettings, ...payload }
  },
  /**
   * Mutate Form Settings from saved form
   */
  [APPLY_FOOTER_FORM_SETTINGS](state, payload) {
    // Update the formSettings
    const footerFormSettings = state.footerFormSettings
    state.footerFormSettings = { ...footerFormSettings, ...payload }
  },
  /**
   * Mutate Form Settings from saved form
   */
  [APPLY_NAVBAR_FORM_SETTINGS](state, payload) {
    // Update the formSettings
    const navbarFormSettings = state.navbarFormSettings
    state.navbarFormSettings = { ...navbarFormSettings, ...payload }
  },
  /**
   * Mutate Form Settings from update form
   */
  [UPDATE_FORM_SETTINGS](state, payload) {
    // Update the formSettings
    const formSettings = state.formSettings
    state.formSettings = { ...formSettings, ...payload }
  },
  /**
   * Set global components to store
   */
  [SET_COMPONENTS](state, payload) {
    // const components = state.components
    const registeredComponents = []
    for (const component in payload) {
      registeredComponents.push(payload[component])
    }
    state.components = [...registeredComponents]
  },
  /**
   * Set the selected page in the store
   */
  [SET_SELECTED_PAGE](state, payload) {
    state.selectedPage = payload
  },
  /**
   * Set the location pages in the store
   */
  [SET_LOCATION_PAGES](state, payload) {
    state.locationPages = payload
  },
  /**
   * Set is editor add page in store
   */
  [SET_EDITOR_ADD](state, payload) {
    state.isEditorAddPage = payload
  },
  /**
   * Mutate the editor has changes state
   */
  [UPDATE_EDITOR_HAS_CHANGES](state, payload) {
    state.editorHasChanges = payload
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
