/**
 * Blog post state
 */
import * as _ from "lodash"
import { buildPropSchemas } from "@/utils/site-builder"
import { ApiFactory } from "@/utils/apis/ApiFactory"
const ComponentSchemaApi = ApiFactory.get("componentSchema")

// Mutation types
const SET_COMPONENTS = "SET_COMPONENTS"
const CREATE_COMPONENT = "CREATE_COMPONENT"
const UPDATE_COMPONENT = "UPDATE_COMPONENT"
// const DELETE_COMPONENT = 'DELETE_COMPONENT'
const SET_SELECTED_COMPONENT = "SET_SELECTED_COMPONENT"
import Vue from "vue"

const state = () => ({
  components: [],
  selectedComponentSchema: null,
})

/**
 * Some sweet, canned corned getters for our blog posts.
 */
const getters = {
  getComponents: (state) => state.components,
  getSelectedComponentSchema: (state) => state.selectedComponentSchema,
}

/**
 * CRUD actions to database
 */
const actions = {
  /**
   * Set website components
   *
   * @todo
   * - Add components first from database, and then update with local
   */
  setComponents({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const websiteComponents = payload.filter((component) => component.extendOptions.__file.includes("src/components"))

        const transformedComponents = []

        // Create stringify mapper for props that we want to be
        // a string value instead of an object.
        //
        // This is typically done because the object can be a config
        // property that contains Nth degree of key-value pairs.
        const stringifyMapper = { swiperOptions: true }

        // Components from local
        websiteComponents.forEach((component, idx) => {
          const extendOptions = component.extendOptions
          const name = extendOptions.name ? extendOptions.name : ""
          const bitArray = extendOptions.__file.split("/")
          const bitFileName = _.kebabCase(bitArray[bitArray.length - 1].split(".")[0])
          const bitExtension = bitArray && bitArray.length ? `${bitArray[2]}.${bitFileName}` : ""

          const default_component_type = extendOptions.__file && extendOptions.__file.includes("src/components/sections") ? "section" : "component"

          // Build dummy prop schemas
          const propSchemas = buildPropSchemas(extendOptions, stringifyMapper)

          // Build Component Schema Object
          const component_schema = {
            bit_component_name: name,
            bit_component_url: `@bit/huddlehouse.pizza-palette.${bitExtension}`,
            type: default_component_type,
            name,
            prop_schemas: propSchemas,
          }
          transformedComponents.push(component_schema)
        })

        // Get from database
        const component_response = await ComponentSchemaApi.all(null, "prop_schemas,prop_schemas.options,prop_schemas.children,prop_schemas.children.children,prop_schemas.children.options")
        const schema_data = component_response.data.data

        // Merge two arrays
        const mergedArray = _.unionBy(schema_data, transformedComponents, "bit_component_name")

        commit(SET_COMPONENTS, mergedArray)
        return resolve({ success: true })
      } catch (error) {
        Vue.rollbar.error(error)
        return reject({ success: false, error })
      }
    })
  },
  /**
   * Async create component schema in database, then mutate store
   */
  createComponentSchema({ dispatch, commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const save_response = await ComponentSchemaApi.create(payload)

        if (save_response && save_response.data && save_response.data.data) {
          commit(CREATE_COMPONENT, save_response.data.data)
        }

        return resolve({ success: true })
      } catch (error) {
        Vue.rollbar.error(error)
        return reject({ success: false, error })
      }
    })
  },
  /**
   * Async update component schema in database, then mutate store
   */
  updateComponentSchema({ dispatch, commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        if (payload && payload.schema && !payload.schema.id) {
          return await dispatch("createComponentSchema", payload)
        }

        const update_response = await ComponentSchemaApi.update(payload)
        const data = update_response.data.data
        if (data) {
          commit(UPDATE_COMPONENT, data)
        }

        return resolve({ success: true })
      } catch (error) {
        Vue.rollbar.error(error)
        return reject({ success: false, error })
      }
    })
  },
  /**
   * Async query for component
   */
  queryComponentSchema({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let where
        if (payload.bit_component_name) {
          where = `{ "bit_component_name": "${payload.bit_component_name}" }`
        }
        const relations = "prop_schemas,prop_schemas.options,prop_schemas.children,prop_schemas.children.children,prop_schemas.children.options"
        const response = await ComponentSchemaApi.all(null, relations, where)
        const selected_component = response.data.data[0]
        commit(SET_SELECTED_COMPONENT, selected_component)
        return resolve({ success: true })
      } catch (error) {
        Vue.rollbar.error(error)
        reject({ success: false, error })
      }
    })
  },
  deleteComponentSchema({ commit }, payload) {},
}

/**
 * CRUD mutations for the store
 */
const mutations = {
  /**
   * Set global components to store
   */
  [SET_COMPONENTS](state, payload) {
    const components = state.components
    const registeredComponents = []
    for (const component in payload) {
      registeredComponents.push(payload[component])
    }
    state.components = [...components, ...registeredComponents]
  },
  [SET_SELECTED_COMPONENT](state, payload) {
    state.selectedComponentSchema = payload
  },
  [CREATE_COMPONENT](state, payload) {
    const components = state.components
    const index = components.findIndex((c) => c.bit_component_name === payload.bit_component_name)
    state.components.splice(index, 1, payload)
  },
  [UPDATE_COMPONENT](state, payload) {
    const components = state.components
    const index = components.findIndex((c) => c.bit_component_name === payload.bit_component_name)
    state.components.splice(index, 1, payload)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
