import axios from "axios"
import { generateApiUrl } from "@/utils/helpers"
import { config } from "@/config"

export const state = () => ({
  unitCachesStatus: {},
})

export const actions = {
  /**
   * Action to trigger a unit cache upload for a Sitelink or Unit Manager Website
   */
  async updateUnitCache({ rootGetters }) {
    const account = rootGetters["accounts/getSelectedAccount"]
    const website = rootGetters["website/getSelectedWebsite"]

    if (account && account.use_insights_unit_manager === true) {
      await axios.post(generateApiUrl("events/websocket"), { message: `${website.has_been_deployed_to_staging == true ? "production_dev" : config.stage} - ${account.id} - UpdateInsightsUnitCache`, data: { message: "UpdateInsightsUnitCache" } })
      return await axios.post(generateApiUrl("events/websocket/action"), { account_id: account.id, stage: website.has_been_deployed_to_staging == true ? "production_dev" : config.stage, action: "UpdateInsightsUnitCache" })
    } else if (account && account.use_insights_unit_manager === false) {
      await axios.post(generateApiUrl("events/websocket"), { message: `${website.has_been_deployed_to_staging == true ? "production_dev" : config.stage} - ${account.id} - UpdateUnitCache`, data: { message: "UpdateUnitCache" } })
      return await axios.post(generateApiUrl("events/websocket/action"), { account_id: account.id, stage: website.has_been_deployed_to_staging == true ? "production_dev" : config.stage, action: "UpdateUnitCache" })
    }

    return false
  },
}

export const mutations = {
  updateUnitCacheStatus(state, payload) {
    state.unitCachesStatus[payload.account_id] = payload.status
  },
}

export const getters = {
  getUnitCacheStatus(state) {
    return state.unitCachesStatus
  },
}
