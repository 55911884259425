/**
 * Units
 */
import { ApiFactory } from "@/utils/apis/ApiFactory"
const FormApi = ApiFactory.get("form")
import _ from "lodash"
import * as uuid from "uuid/v1"
import Vue from "vue"

const SET_FORMS = "SET_FORMS"

const state = () => ({
  // form: {
  //   name: '',
  //   sections: [
  //     {
  //       id: uuid(),
  //       title: '',
  //       fields: []
  //     }
  //   ]
  // },
  forms: [],
  sections: [
    {
      id: uuid(),
      title: "",
      fields: [],
    },
  ],
  activeField: {},
  activeFieldSectionIndex: 0,
  activeFieldIndex: 0,
  activeTabForFields: "elements",
})

const getters = {
  getSections: (state) => state.sections,
  getActiveField: (state) => state.activeField,
  getActiveFieldSectionIndex: (state) => state.activeFieldSectionIndex,
  getActiveFieldIndex: (state) => state.activeFieldIndex,
  getActiveTabForFields: (state) => state.activeTabForFields,
  getForms: (state) => state.forms,
  // getForm: (state) => state.form
}

const actions = {
  /**
   * Get forms from database and set them in the store
   */
  setForms({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        // If no payload set forms from database
        if (!payload) {
          const { data: response } = await FormApi.all(null, null, null, 10000, null, "name")
          // Mutate store
          commit(SET_FORMS, response.data)
          resolve(response)
        } else {
          commit(SET_FORMS, payload)
          resolve(payload)
        }
      } catch (error) {
        Vue.rollbar.error('Store: Error setting forms', error)
        reject(error)
      }
    })
  },
}

const mutations = {
  // updateForm(state, payload) {
  //   state.form = { ...state.form, ...payload }
  // },
  [SET_FORMS](state, payload) {
    state.forms = payload
  },
  setSections(state, payload) {
    state.sections = payload
  },
  setActiveFieldSectionIndex(state, payload) {
    state.activeFieldSectionIndex = payload
  },
  setActiveFieldIndex(state, payload) {
    state.activeFieldIndex = payload
  },
  addSection(state, payload) {
    let tmp = _.cloneDeep(state.sections)
    payload.id = uuid()
    tmp.push(payload)
    state.sections = tmp
  },
  deleteSection(state, payload) {
    let tmp = _.cloneDeep(state.sections)
    tmp.splice(payload, 1)
    state.sections = tmp
  },
  setActiveField(state, payload) {
    if (payload !== null) {
      state.activeField = payload
      state.sections[state.activeFieldSectionIndex].fields[state.activeFieldIndex] = state.activeField
    } else {
      state.activeField = {}
      // state.sections[state.activeFieldSectionIndex].fields.splice(
      //   state.getActiveFieldIndex,
      //   1
      // )
    }
  },
  setActiveTabForFields(state, payload) {
    state.activeTabForFields = payload
  },
  resetFormBuilder(state, payload) {
    Object.assign(state, {
      sections: [
        {
          id: uuid(),
          title: "",
          fields: [],
        },
      ],
      activeField: {},
      activeFieldSectionIndex: 0,
      activeFieldIndex: 0,
      activeTabForFields: "elements",
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
