/**
 * Blog post state
 */
import * as uuid from "uuid/v1"
import * as _ from "lodash"
import { ApiFactory } from "@/utils/apis/ApiFactory"
const ContentApi = ApiFactory.get("content")
import Vue from "vue"

// Mutation types
const SET_CONTENT = "SET_CONTENT"
const SAVE_CONTENT = "SAVE_CONTENT"
const SAVE_CONTENT_CHANGES = "SAVE_CONTENT_CHANGES"
const DELETE_CONTENT_ITEM = "DELETE_CONTENT_ITEM"

const state = () => ({
  content: [],
})

/**
 * Some sweet, canned corned getters for our blog posts.
 */
const getters = {
  /**
   * Return all content
   */
  getContent: (state) => state.content,
}

const actions = {
  /**
   * Initialize Vuex with content
   */
  setContent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(SET_CONTENT, payload)
        resolve(payload)
      } catch (e) {
        console.error("Error with content/setContent: ", e)
        Vue.rollbar.error("Error with content/setContent: ", e)
        reject(e)
      }
    })
  },
  /**
   * Save content to database
   *
   * - Update store with updated data
   */
  saveContent({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const accountId = rootGetters["user/getSelectedAccountId"]

        // Save content to database
        const { data: response } = await ContentApi.create(payload, accountId)
        const saved_content = response.data

        // Update store
        commit(SAVE_CONTENT, saved_content)
        resolve(saved_content)
      } catch (e) {
        console.error("Error with content/setContent: ", e)
        Vue.rollbar.error("Error with content/setContent: ", e)
        reject(e)
      }
    })
  },
  /**
   * Save content changes. We batch save the changes when a list has
   * been re-ordered, and we need to update the database with the updated
   * content index values.
   */
  saveContentChanges({ commit, rootGetters, state }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const accountId = rootGetters["user/getSelectedAccountId"]

        // Save content to database
        const { data: response } = await ContentApi.create(payload.items, accountId)
        const saved_content = response.data

        // Filter opposite list
        const clonedListValue = _.cloneDeep(state.content).filter((item) => item.tag !== payload.tag)

        // Merge lists
        const mergedLists = clonedListValue.concat(saved_content)

        // Update store
        commit(SAVE_CONTENT_CHANGES, mergedLists)
        resolve(mergedLists)
      } catch (e) {
        console.error("Error with content/saveContentChanges: ", e)
        Vue.rollbar.error("Error with content/saveContentChanges: ", e)
        reject(e)
      }
    })
  },
  /**
   * Delete item from database and from the store.
   */
  deleteContentItem({ commit, rootGetters }, id) {
    return new Promise(async (resolve, reject) => {
      try {
        const accountId = rootGetters["user/getSelectedAccountId"]

        // Save content to database
        const { data: response } = await ContentApi.delete(id, accountId)
        const deleted_content = response.data

        // Update store
        commit(DELETE_CONTENT_ITEM, id)
        resolve(deleted_content)
      } catch (e) {
        console.error("Error with content/saveContentChanges: ", e)
        Vue.rollbar.error("Error with content/saveContentChanges: ", e)
        reject(e)
      }
    })
  },
  /**
   * Clone post.
   *
   * @todo We will add an api call to add cloned post.
   */
  // cloneMenu({ commit, rootGetters }, payload) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const accountId = rootGetters['user/getSelectedAccountId']
  //       const { data: response } = await NavMenuApi.create(payload, accountId)
  //       const menu = response.data

  //       commit(CLONE_MENU, menu)

  //       return resolve(menu.id)
  //     } catch (e) {
  //       Vue.rollbar.error(e)
  //       return reject(e)
  //     }
  //   })
  // },
}

const mutations = {
  /**
   * Set initial content to store
   */
  [SET_CONTENT](state, payload) {
    // const content = state.content
    state.content = payload
  },
  /**
   * Add or update saved content item
   */
  [SAVE_CONTENT](state, payload) {
    const content = state.content
    const index = content.findIndex((item) => item.id === payload.id)

    if (index > -1 && payload.id) {
      // Update content item
      state.content.splice(index, 1, payload)
    } else {
      if (_.isArray(payload)) {
        state.content.push(...payload)
      } else {
        // Add item to content list
        state.content.push(payload)
      }
    }
  },
  /**
   * Batch save content
   */
  [SAVE_CONTENT_CHANGES](state, payload) {
    state.content = payload
  },
  /**
   * Remove deleted the post from the Blog state
   */
  [DELETE_CONTENT_ITEM](state, id) {
    const content = state.content
    const itemIndex = content.findIndex((item) => item.id === id)

    if (itemIndex > -1) {
      state.content.splice(itemIndex, 1)
    }
  },
  /**
   * Add cloned content item
   */
  // [CLONE_CONTENT_ITEM](state, payload) {
  //   // Get blog account
  //   const content = state.content
  //   const itemIndex = content.findIndex((c) => c.id === payload.id)

  //   // Get post index
  //   const blog = blogs[bIndex]
  //   const posts = blog.posts
  //   const pIndex = posts.findIndex((p) => p.id === oldId)
  //   posts.splice(pIndex + 1, 0, payload)

  //   state.blogs.splice(bIndex, 1, blog)
  // },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
