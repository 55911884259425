import _ from "lodash"
import { ApiFactory } from "@/utils/apis/ApiFactory"
import Vue from "vue"
const SAVE_THEMES = "SAVE_THEMES"
const ADD_THEME = "ADD_THEME"
const THEMES_LOADED = "THEMES_LOADED"

const SAVE_SECTIONS = "SAVE_SECTIONS"
const ADD_SECTION = "ADD_SECTION"
const SAVE_PAGES = "SAVE_PAGES"
const ADD_PAGE = "ADD_PAGE"
const SAVE_TAGS = "SAVE_TAGS"
const SET_TAG_FILTERS = "SET_TAG_FILTERS"
const TAGS_LOADED = "TAGS_LOADED"
const PAGES_LOADED = "PAGES_LOADED"
const SECTIONS_LOADED = "SECTIONS_LOADED"

const SectionSchemaApi = ApiFactory.get("sectionSchema")
const PageSchemaApi = ApiFactory.get("pageSchema")
const ThemeSchemaApi = ApiFactory.get("themeSchema")
const SchemaTagApi = ApiFactory.get("schemaTag")

const state = () => ({
  custom_sections: [],
  has_loaded_custom_sections: false,
  custom_pages: [],
  has_loaded_custom_pages: false,
  custom_themes: [],
  has_loaded_custom_themes: false,
  selected_tag_filters: [],
  tags: [],
  has_loaded_tags: false,
})

const getters = {
  getCustomThemes(state) {
    return state.custom_themes.filter((item) => {
      if (state.selected_tag_filters.length === 0) {
        return item
      } else if (_.intersectionBy(item.tags, state.selected_tag_filters, "name").length > 0) {
        return item
      }
    })
  },
  getCustomSections(state) {
    return state.custom_sections.filter((item) => {
      if (state.selected_tag_filters.length === 0) {
        return item
      } else if (_.intersectionBy(item.tags, state.selected_tag_filters, "name").length > 0) {
        return item
      }
    })
  },
  getCustomPages(state) {
    return state.custom_pages.filter((item) => {
      if (state.selected_tag_filters.length === 0) {
        return item
      } else if (_.intersectionBy(item.tags, state.selected_tag_filters, "name").length > 0) {
        return item
      }
    })
  },
  getTags(state) {
    return state.tags
  },
  getHasTagsLoaded(state) {
    return state.has_loaded_tags
  },
  getSelectedTagFilters(state) {
    return state.selected_tag_filters
  },
}

const actions = {
  setSelectedTagFilters({ commit, rootGetters, state }, payload) {
    commit(SET_TAG_FILTERS, payload)
  },
  loadTags({ commit, rootGetters, state }, payload) {
    return new Promise(async (resolve, reject) => {
      if (state.has_loaded_tags === false) {
        try {
          const { data: res } = await SchemaTagApi.all(null, null, null, 1000000)
          const sorted = res.data.sort(function (a, b) {
            var nameA = a.name.toLowerCase()
            var nameB = b.name.toLowerCase()
            if (nameA < nameB) {
              // sort string ascending
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0 // default return value (no sorting)
          })
          // console.log(res)
          commit(SAVE_TAGS, sorted)
          commit(TAGS_LOADED, true)
          return resolve()
        } catch (e) {
          Vue.rollbar.error(e)
          return reject(e)
        }
      } else {
        return resolve()
      }
    })
  },
  loadCustomPages({ commit, rootGetters, state }, payload) {
    return new Promise(async (resolve, reject) => {
      if (state.has_loaded_custom_pages === false) {
        try {
          const { data: res } = await PageSchemaApi.all(null, "image,tags", null, 1000000)
          const sorted = res.data.sort(function (a, b) {
            var nameA = a.name.toLowerCase()
            var nameB = b.name.toLowerCase()
            if (nameA < nameB) {
              // sort string ascending
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0 // default return value (no sorting)
          })
          // console.log(sorted)
          commit(SAVE_PAGES, sorted)
          commit(PAGES_LOADED, true)
          return resolve()
        } catch (e) {
          Vue.rollbar.error(e)
          return reject(e)
        }
      } else {
        return resolve()
      }
    })
  },
  loadCustomSections({ commit, rootGetters, state }, payload) {
    return new Promise(async (resolve, reject) => {
      // console.log(payload)
      if (state.has_loaded_custom_sections === false) {
        try {
          const { data: res } = await SectionSchemaApi.all(null, "image,tags", null, 1000000)
          const sorted = res.data.sort(function (a, b) {
            var nameA = a.name.toLowerCase()
            var nameB = b.name.toLowerCase()
            if (nameA < nameB) {
              // sort string ascending
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0 // default return value (no sorting)
          })
          // console.log(res)
          commit(SAVE_SECTIONS, sorted)
          commit(SECTIONS_LOADED, true)
          return resolve()
        } catch (e) {
          Vue.rollbar.error(e)
          return reject(e)
        }
      } else {
        return resolve()
      }
    })
  },
  loadCustomThemes({ commit, rootGetters, state }, payload) {
    return new Promise(async (resolve, reject) => {
      if (state.has_loaded_custom_themes === false) {
        try {
          const { data: res } = await ThemeSchemaApi.all(null, "image,tags", null, 1000000)
          const sorted = res.data.sort(function (a, b) {
            var nameA = a.name.toLowerCase()
            var nameB = b.name.toLowerCase()
            if (nameA < nameB) {
              // sort string ascending
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0 // default return value (no sorting)
          })
          // console.log(res)
          commit(SAVE_THEMES, sorted)
          commit(THEMES_LOADED, true)
          return resolve()
        } catch (e) {
          console.error("🚀 ~ file: customThemes.js ~ line 192 ~ returnnewPromise ~ e", e)
          Vue.rollbar.error(e)
          return reject(e)
        }
      } else {
        return resolve()
      }
    })
  },
  saveCustomTheme({ commit, dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data: res } = await ThemeSchemaApi.saveCustomThemeSchemaApi(payload)
        commit(ADD_SECTION, res)
        commit(TAGS_LOADED, false)
        dispatch("loadTags", null)
        return resolve()
      } catch (e) {
        Vue.rollbar.error(e)
        return reject(e)
      }
    })
  },
  saveCustomSection({ commit, dispatch, }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data: res } = await SectionSchemaApi.saveCustomSectionSchemaApi(payload)
        commit(ADD_SECTION, res)
        commit(TAGS_LOADED, false)
        dispatch("loadTags", null)
        return resolve()
      } catch (e) {
        Vue.rollbar.error(e)
        return reject(e)
      }
    })
  },
  saveCustomPage({ commit, dispatch, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        // Added current user as the author
        const user = rootGetters["user/getUser"]
        if (user) {
          payload.author = user.name || user.email
        }

        const { data: res } = await PageSchemaApi.saveCustomPageSchemaApi(payload)
        commit(ADD_PAGE, res)
        commit(TAGS_LOADED, false)
        dispatch("loadTags", null)
        return resolve()
      } catch (e) {
        Vue.rollbar.error(e)
        return reject(e)
      }
    })
  },
  applyCustomThemeSchemaToAWebsite({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        var account_id = rootGetters["user/getSelectedAccountId"]
        const { data: res } = await ThemeSchemaApi.applyCustomThemeSchemaToAWebsite(payload.pages, account_id, payload.theme_id)
        // console.log(res)
        return resolve(res)
      } catch (e) {
        Vue.rollbar.error(e)
        return reject(e)
      }
    })
  },
}

const mutations = {
  [PAGES_LOADED](state, payload) {
    state.has_loaded_custom_pages = payload
  },
  [SECTIONS_LOADED](state, payload) {
    state.has_loaded_custom_sections = payload
  },
  [THEMES_LOADED](state, payload) {
    state.has_loaded_custom_sections = payload
  },
  [SAVE_SECTIONS](state, payload) {
    state.custom_sections = payload
  },
  [TAGS_LOADED](state, payload) {
    state.has_loaded_tags = payload
  },
  [SAVE_TAGS](state, payload) {
    state.tags = payload
  },
  [SET_TAG_FILTERS](state, payload) {
    state.selected_tag_filters = payload
  },
  [ADD_SECTION](state, payload) {
    state.custom_sections.push(payload)
  },
  [SAVE_PAGES](state, payload) {
    state.custom_pages = payload
  },
  [ADD_PAGE](state, payload) {
    state.custom_pages.push(payload)
  },
  [SAVE_THEMES](state, payload) {
    state.custom_themes = payload
  },
  [ADD_THEME](state, payload) {
    state.custom_themes.push(payload)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
