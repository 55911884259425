/**
 * Account contacts
 */
import * as uuid from "uuid/v1"
import { ApiFactory } from "@/utils/apis/ApiFactory"
const ContactApi = ApiFactory.get("contact")
import Vue from "vue"

// Mutation types
const SET_CONTACTS = "SET_CONTACTS"
const CLONE_CONTACT = "CLONE_CONTACT"
const DELETE_CONTACT = "DELETE_CONTACT"

const state = () => ({
  contacts: [],
})

/**
 * Some sweet, canned corned getters for our blog posts.
 */
const getters = {
  /**
   * Return all contacts
   */
  getContacts: (state) => state.contacts,
  /**
   * Return contact by id
   */
  getContactById: (state) => (id) => {
    return state.contacts.find((contact) => contact.id === id)
  },
}

/**
 * @todo
 * - Add action to retrieve all posts from DB
 * - Add action to find post from DB
 */
const actions = {
  /**
   * Initialize Vuex with account contacts
   */
  async setContacts({ commit, rootGetters }, payload) {
    let account = rootGetters["user/getSelectedAccountId"]
    let relations = ""

    if (payload && payload.account) {
      account = payload.account
    }

    if (payload && payload.relations) {
      relations += "," + payload.relations
    }

    try {
      // Make async API call and mutate store
      const response = await ContactApi.all(account, relations)
      const contact_payload = response.data.data

      // Mutate store
      if (contact_payload) {
        commit(SET_CONTACTS, contact_payload)
      }
    } catch (e) {
      Vue.rollbar.error('Store: Error setting contacts', e)
    }
  },
  /**
   * Clone contact.
   *
   * @todo We will add an api call to add cloned contact.
   */
  cloneContact({ commit }, payload) {
    const newIdPayload = {
      ...payload,
      id: uuid(),
    }
    commit(CLONE_CONTACT, { oldId: payload.id, payload: newIdPayload })
  },
  /**
   * Delete contact.
   *
   * @todo We will add an api call to delete contact.
   */
  deleteContact({ commit }, id) {
    commit(DELETE_CONTACT, id)
  },
}

/**
 * @todo
 * - Add mutation to update all contacts from DB
 */
const mutations = {
  /**
   * Set initial contacts to store
   */
  [SET_CONTACTS](state, payload) {
    const contacts = state.contacts
    state.contacts = [...contacts, ...payload]
  },
  /**
   * Add cloned post to Contact state
   */
  [CLONE_CONTACT](state, { oldId, payload }) {
    const contacts = state.contacts
    const index = contacts.findIndex((c) => c.id === oldId)
    state.contacts.splice(index + 1, 0, payload)
  },
  /**
   * Remove deleted the contact from the Contact state
   */
  [DELETE_CONTACT](state, id) {
    const contacts = state.contacts
    const index = contacts.findIndex((n) => n.id === id)
    state.contacts.splice(index, 1)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
