// Mutation types
const UPDATE_COLORS = 'UPDATE_COLORS'
const UPDATE_BODY_FONT = 'UPDATE_BODY_FONT'
const UPDATE_HEADING_FONT = 'UPDATE_HEADING_FONT'
const UPDATE_HAS_LOADED_GOOGLE_APIS = 'UPDATE_HAS_LOADED_GOOGLE_APIS'

const state = () => ({
  colors: {
    mainColor: '#f8f8f8',
    alternativeColor: '#f11721',
    backgroundColor: '#f81421',
    textColor: '#aa1711',
    headingColor: '#abab21',
    linkColor: '#f8cb21',
    alertColor: '#f81c21',
    successColor: '#aa1721'
  },
  fonts: {
    bodyTextFont: {
      text: 'Open Sans Family',
      value: 'open_sans'
    },
    headingFont: {
      text: 'Helvetica',
      value: 'helvetica'
    },
    available: [
      {
        text: 'Open Sans Family',
        value: 'open_sans'
      },
      {
        text: 'Helvetica',
        value: 'helvetica'
      },
      {
        text: 'Fira Code',
        value: 'fira_code'
      }
    ]
  },
  hasLoadedGoogleApis: false
})

const getters = {
  getColors: state => state.colors,
  getAvailableFonts: state => state.fonts.available,
  getHasLoadedGoogleApis: state => state.hasLoadedGoogleApis
}

const actions = {
  updateColors({ commit }, payload) {
    commit(UPDATE_COLORS, payload)
  },

  updateFonts({ commit }, payload) {
    const { bodyTextFont, headingFont } = payload

    const validBodyTextFont = state.fonts.available.filter((font) => {
      return font.value === bodyTextFont
    })

    const validHeadingFont = state.fonts.available.filter((font) => {
      return font.value === headingFont
    })

    if (validBodyTextFont) {
      commit(UPDATE_BODY_FONT, validBodyTextFont[0])
    }

    if (validHeadingFont) {
      commit(UPDATE_HEADING_FONT, validHeadingFont[0])
    }
  },

  updateHasLoadedGoogleApis({ commit }, payload) {
    commit(UPDATE_HAS_LOADED_GOOGLE_APIS, payload)
  }
}

const mutations = {
  [UPDATE_COLORS](state, payload) {
    const colors = state.colors
    state.colors = {
      ...colors,
      ...payload
    }
  },

  [UPDATE_BODY_FONT](state, payload) {
    state.fonts.bodyTextFont = {
      ...payload
    }
  },

  [UPDATE_HEADING_FONT](state, payload) {
    state.fonts.headingFont = {
      ...payload
    }
  },

  [UPDATE_HAS_LOADED_GOOGLE_APIS](state, payload) {
    state.hasLoadedGoogleApis = payload
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
