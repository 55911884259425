import { ApiFactory } from "@/utils/apis/ApiFactory"
const SitelinkApi = ApiFactory.get("sitelink")

const SET_USER = "SET_USER"
const SET_ACCOUNT = "SET_ACCOUNT"
const SET_WEBSITE = "SET_WEBSITE"
const SET_LOCATIONS = "SET_LOCATIONS"
const SET_SELECTED_LOCATION = "SET_SELECTED_LOCATION"
const SET_SELECTED_PAGE = "SET_SELECTED_PAGE"
const SET_UNITS = "SET_UNITS"
const UPDATE_PAGE_INFO = "UPDATE_PAGE_INFO"
const SET_PAGES = "SET_PAGES"
import Vue from "vue"

const state = () => ({
  isUserLoggedIn: false,
  selectedLocation: {
    id: "043524fe-cbdf-4894-8f21-202215f3ac5d",
    created_at: "2019-10-04T20:33:18.663Z",
    updated_at: "2019-11-06T22:58:08.000Z",
    version: 4,
    account_id: "2",
    name: "Monahan Throughway",
    url_slug: "",
    meta_title: "Realigned client-server open system",
    meta_tagline: "Extended high-level groupware",
    meta_description: "Intuitive bifurcated installation",
    software: "sitelink",
    phone_number: "(132) 917-2908 x0756",
    sms: "1-570-017-8214",
    email: "Alden93@gmail.com",
    units_quantity: null,
    facebook: "",
    yelp: "",
    google: "",
    twitter: "",
    sparefoot: "",
    settings: null,
    address: {
      id: "4ebd3191-24b7-4964-9334-7555b1ff2d89",
      created_at: "2019-10-04T20:33:24.629Z",
      updated_at: "2019-10-04T20:33:24.629Z",
      version: 1,
      street_1: "123 Happy Street",
      street_2: "",
      state_province: "UT",
      city: "Diannaville",
      postal: "74773",
      country: "US",
      lat: "-22.8379",
      lon: "-99.7882",
    },
    meta_image: {
      id: "0e0ff442-45f1-473e-ab5e-f44707e29aea",
      created_at: "2019-10-04T20:33:29.231Z",
      updated_at: "2019-10-04T20:33:29.231Z",
      version: 1,
      account_id: "2",
      type: "picture",
      alt: "Maxime ea dolorem repudiandae sed sed quisquam perferendis enim.",
      src: "https://placeimg.com/100/100/any",
      title: "Atque culpa saepe sit omnis deserunt quidem exercitationem animi qui.",
      is_global: false,
      is_icon: false,
    },
    review_settings: {
      id: "8821fdfb-e52f-4007-a32c-d3a669ba2f00",
      created_at: "2019-10-30T02:28:43.159Z",
      updated_at: "2019-10-30T02:29:58.000Z",
      version: 5,
      batch_id: "277778206",
      status: "get-results",
      total_reviews: 43,
      rating: 5,
    },
    insurance: [
      {
        id: "5a537921-bcbf-4cb3-9692-92b1e234ed01",
        created_at: "2019-11-07T16:01:42.336Z",
        updated_at: "2019-11-07T16:01:42.336Z",
        version: 1,
        sitelink_id: "574",
        coverage: 2000,
        percent_of_theft_coverage: 1,
        site_id: "7021",
        description: "100%",
        premium: 8,
        provider: "Bader Company",
      },
      {
        id: "6b2a5010-ef1a-4469-ae97-3218bb0cad7b",
        created_at: "2019-11-07T16:01:42.340Z",
        updated_at: "2019-11-07T16:01:42.340Z",
        version: 1,
        sitelink_id: "588",
        coverage: 20000,
        percent_of_theft_coverage: 1,
        site_id: "7021",
        description: "100%",
        premium: 75,
        provider: "Bader Company",
      },
      {
        id: "8d116c77-47a5-4afb-b75f-bc01291a4371",
        created_at: "2019-11-07T16:01:42.339Z",
        updated_at: "2019-11-07T16:01:42.339Z",
        version: 1,
        sitelink_id: "582",
        coverage: 10000,
        percent_of_theft_coverage: 1,
        site_id: "7021",
        description: "100%",
        premium: 38,
        provider: "Bader Company",
      },
      {
        id: "aafdc550-03a6-41f8-92d5-2b4b76a7fdda",
        created_at: "2019-11-07T16:01:42.338Z",
        updated_at: "2019-11-07T16:01:42.338Z",
        version: 1,
        sitelink_id: "578",
        coverage: 5000,
        percent_of_theft_coverage: 1,
        site_id: "7021",
        description: "100%",
        premium: 20,
        provider: "Bader Company",
      },
      {
        id: "c91c6d76-6b16-4f7d-97d6-916bab7059b0",
        created_at: "2019-11-07T16:01:42.337Z",
        updated_at: "2019-11-07T16:01:42.337Z",
        version: 1,
        sitelink_id: "576",
        coverage: 3000,
        percent_of_theft_coverage: 1,
        site_id: "7021",
        description: "100%",
        premium: 12,
        provider: "Bader Company",
      },
      {
        id: "db704324-3bf6-4d34-804a-bcb5a53f6508",
        created_at: "2019-11-07T16:01:42.339Z",
        updated_at: "2019-11-07T16:01:42.339Z",
        version: 1,
        sitelink_id: "586",
        coverage: 15000,
        percent_of_theft_coverage: 1,
        site_id: "7021",
        description: "100%",
        premium: 57,
        provider: "Bader Company",
      },
    ],
    payment_types: [
      {
        id: "2e117073-81b9-42a4-abf8-62b97770b070",
        created_at: "2019-11-07T16:01:42.619Z",
        updated_at: "2019-11-07T16:01:42.619Z",
        version: 1,
        value: 193,
        label: "Visa",
        category: "visa",
      },
      {
        id: "79c4cdf0-75c4-4d8f-838e-678eb2456074",
        created_at: "2019-11-07T16:01:42.620Z",
        updated_at: "2019-11-07T16:01:42.620Z",
        version: 1,
        value: 196,
        label: "Diners Club",
        category: "diners",
      },
      {
        id: "a004488c-cdbc-476e-9eb4-3e0753fc231d",
        created_at: "2019-11-07T16:01:42.617Z",
        updated_at: "2019-11-07T16:01:42.617Z",
        version: 1,
        value: 192,
        label: "Master Card",
        category: "mastercard",
      },
      {
        id: "ada74ca2-b264-46dc-8ab8-65d560255df1",
        created_at: "2019-11-07T16:01:42.620Z",
        updated_at: "2019-11-07T16:01:42.620Z",
        version: 1,
        value: 195,
        label: "Discover",
        category: "discover",
      },
    ],
    amenities: [
      {
        id: "0d286c72-d718-4aed-9b20-07b69aae193f",
        created_at: "2019-11-01T18:48:54.374Z",
        updated_at: "2019-11-01T18:48:54.374Z",
        version: 1,
        icon: "/_nuxt/assets/img/ic-air-filled.svg",
        label: "Air-conditioned Units",
        name: "ic-air-filled.svg",
      },
      {
        id: "20931178-0f78-47c6-a744-a0bc9bd6ead1",
        created_at: "2019-11-01T18:48:54.394Z",
        updated_at: "2019-11-01T18:48:54.394Z",
        version: 1,
        icon: "/_nuxt/assets/img/ic-acessible-filled.svg",
        label: "24/7 Access",
        name: "ic-acessible-filled.svg",
      },
    ],
    hours: [
      {
        id: "32683acb-ec59-4665-962d-f32fcd83eb5c",
        created_at: "2019-10-04T20:33:00.811Z",
        updated_at: "2019-10-04T20:33:39.000Z",
        version: 2,
        type: "office",
        show_on_google_my_business: true,
        items: [
          {
            id: "427f5d2f-301b-441c-bb58-60d078b600e8",
            created_at: "2019-10-04T20:33:06.667Z",
            updated_at: "2019-10-04T20:33:06.667Z",
            version: 1,
            id_json_formatted_text: "Sa 09:00-14:00",
            days: "Sa",
            open: "09:00",
            close: "14:00",
            is_open_24_hours: false,
            open_24_hours_text: "Open 24 Hours",
            is_closed: false,
            closed_text: "Closed",
            is_display_text: false,
            display_text: "",
          },
          {
            id: "6e527837-195c-4898-95d5-90bad053a515",
            created_at: "2019-10-04T20:33:06.510Z",
            updated_at: "2019-10-04T20:33:06.510Z",
            version: 1,
            id_json_formatted_text: "Mo,Tu,We,Th,Fr 09:00-18:00",
            days: "Mo,Tu,We,Th,Fr",
            open: "09:00",
            close: "18:00",
            is_open_24_hours: false,
            open_24_hours_text: "Open 24 Hours",
            is_closed: false,
            closed_text: "Closed",
            is_display_text: false,
            display_text: "",
          },
          {
            id: "7a4cac42-039b-4a6f-bdc3-3f0a9270e109",
            created_at: "2019-10-04T20:33:06.812Z",
            updated_at: "2019-10-04T20:33:06.812Z",
            version: 1,
            id_json_formatted_text: "",
            days: "Su",
            open: "09:00",
            close: "14:00",
            is_open_24_hours: false,
            open_24_hours_text: "Open 24 Hours",
            is_closed: true,
            closed_text: "Closed",
            is_display_text: false,
            display_text: "",
          },
        ],
      },
      {
        id: "9131409f-e818-4f9f-b5d9-2b40710a9182",
        created_at: "2019-10-04T20:33:00.968Z",
        updated_at: "2019-10-04T20:33:40.000Z",
        version: 2,
        type: "gate",
        show_on_google_my_business: false,
        items: [
          {
            id: "53f29a4f-63e2-431d-8491-d10981655de0",
            created_at: "2019-10-04T20:33:06.960Z",
            updated_at: "2019-10-04T20:33:06.960Z",
            version: 1,
            id_json_formatted_text: "Mo,Tu,We,Th,Fr,Sa,Su 09:00-22:00",
            days: "Mo,Tu,We,Th,Fr,Sa,Su",
            open: "09:00",
            close: "22:00",
            is_open_24_hours: false,
            open_24_hours_text: "Open 24 Hours",
            is_closed: false,
            closed_text: "Closed",
            is_display_text: false,
            display_text: "",
          },
        ],
      },
    ],
    media: [
      {
        id: "0e0ff442-45f1-473e-ab5e-f44707e29aea",
        created_at: "2019-10-04T20:33:29.231Z",
        updated_at: "2019-10-04T20:33:29.231Z",
        version: 1,
        account_id: "2",
        type: "picture",
        alt: "Maxime ea dolorem repudiandae sed sed quisquam perferendis enim.",
        src: "https://placeimg.com/500/500/any",
        title: "Atque culpa saepe sit omnis deserunt quidem exercitationem animi qui.",
        is_global: false,
        is_icon: false,
      },
      {
        id: "0dfff442-45f1-473e-ab5e-f44707e29aea",
        created_at: "2019-10-04T20:33:29.231Z",
        updated_at: "2019-10-04T20:33:29.231Z",
        version: 1,
        account_id: "2",
        type: "picture",
        alt: "Maxime ea dolorem repudiandae sed sed quisquam perferendis enim.",
        src: "https://placeimg.com/400/500/any",
        title: "Atque culpa saepe sit omnis deserunt quidem exercitationem animi qui.",
        is_global: false,
        is_icon: false,
      },
      {
        id: "0e034442-45f1-473e-ab5e-f44707e29aea",
        created_at: "2019-10-04T20:33:29.231Z",
        updated_at: "2019-10-04T20:33:29.231Z",
        version: 1,
        account_id: "2",
        type: "picture",
        alt: "Maxime ea dolorem repudiandae sed sed quisquam perferendis enim.",
        src: "https://placeimg.com/500/400/any",
        title: "Atque culpa saepe sit omnis deserunt quidem exercitationem animi qui.",
        is_global: false,
        is_icon: false,
      },
    ],
    size_guide_sizes: [],
  },
  selectedUnit: {
    id: "39af89d5-9793-430b-a440-c6df06b7a496",
    created_at: "2019-11-07T15:39:24.309Z",
    updated_at: "2019-11-07T16:02:01.000Z",
    version: 3,
    sitelink_id: "95250",
    rate: 102,
    push_rate: 102,
    street_rate: 102,
    street_push_rate: 102,
    tax: 6,
    floor: 1,
    unit_number: "1839",
    width: 10,
    location_code: "CARL",
    entry_type: "Interior Hallway",
    length: 10,
    is_climate: false,
    is_inside: true,
    is_ada: false,
    has_power: false,
    has_unit_alarms: false,
    is_rentable: true,
    is_rented: false,
    is_on_waiting_list: false,
    standard_security_deposit: "0",
    door_type: null,
    is_mobile: false,
    filter_type_class: "interior",
    size: "md",
    description: "",
    square_feet: 100,
    unit_type_name: "Interior",
    type: {
      id: "6173a41f-244e-46e5-b0f2-b83784bd083b",
      created_at: "2019-11-07T16:01:37.180Z",
      updated_at: "2019-11-07T16:01:37.180Z",
      version: 1,
      name: "Interior",
      sitelink_id: "1419",
      num_decimals_charges: 2,
      num_decimals_taxes: 2,
      tax_rate_1: 6,
      tax_rate_2: 0,
      is_charge_tax_rate_1: true,
      is_charge_tax_rate_2: true,
      is_mobile_unit: false,
      is_climate: false,
      is_power: false,
      is_alarm: false,
      is_inside: true,
      floor_num: 1,
      admin_fee: 20,
      reservation_fee: 20,
      is_exclude_from_insurance: false,
      discounts: [
        {
          id: "2c762ac6-c16a-40ba-8661-21977a43da07",
          created_at: "2019-11-07T15:36:43.533Z",
          updated_at: "2019-11-07T16:01:37.000Z",
          version: 6,
          site_id: "31520",
          type: 1,
          description: "",
          show_on: 0,
          require_prepay_months: 1,
          month_discount_is_applied: "2",
          discount_plan_global_id: 186471955,
          name: "50% off 1st full month",
          charge_category: "Rent",
          sitelink_id: "7140",
          never_expires: false,
          require_prepay: false,
          percentage_off: "50",
          fixed_price: 0,
          fixed_discount: 0,
          maximum_occupancy_percentage: 93,
          exclude_if_unit_total_less_than: 0,
          price: 0,
          is_rounded: false,
          rounded_type: 0,
          max_discount: 0,
          is_for_all_units: false,
          is_disabled: false,
          is_apply_at_move_in: false,
          is_prorated_at_move_in: false,
          tax_rate: 6,
          available_at: 432,
        },
        {
          id: "a86e433b-7798-4301-a17d-ae8852533d1e",
          created_at: "2019-11-07T15:36:43.534Z",
          updated_at: "2019-11-07T16:01:37.000Z",
          version: 6,
          site_id: "31520",
          type: 1,
          description: "",
          show_on: 0,
          require_prepay_months: 1,
          month_discount_is_applied: "2",
          discount_plan_global_id: 1496821215,
          name: "First 2 Full Months 50% Off",
          charge_category: "Rent",
          sitelink_id: "7220",
          never_expires: false,
          require_prepay: false,
          percentage_off: "50",
          fixed_price: 0,
          fixed_discount: 0,
          maximum_occupancy_percentage: 89,
          exclude_if_unit_total_less_than: 5,
          price: 0,
          is_rounded: false,
          rounded_type: 0,
          max_discount: 0,
          is_for_all_units: false,
          is_disabled: false,
          is_apply_at_move_in: false,
          is_prorated_at_move_in: false,
          tax_rate: 6,
          available_at: 432,
        },
      ],
    },
  },
  selectedLedger: {
    ledger_id: 48402,
    sitelink_id: 7348,
    tenant_name: "Pug StoragePug",
    unit_number: "VP17",
    billing_frequency: "Monthly",
    rent: 60,
    move_in_date: "2018-10-29T14:38:57.000Z",
    total_due: 1251.5,
    is_overlocked: false,
    paid_thru: "2018-12-01T04:59:59.997Z",
    lease_num: 1935,
    access_code: 9832982,
    unit: {
      id: "9a45cee7-e6cd-452d-940d-886a8f3662b3",
      created_at: "2019-10-29T21:59:33.792Z",
      updated_at: "2019-11-01T18:55:02.000Z",
      version: 2,
      sitelink_id: "7348",
      rate: 60,
      push_rate: 60,
      street_rate: 60,
      street_push_rate: 60,
      tax: 6,
      floor: 1,
      unit_number: "VP17",
      width: 0,
      location_code: "DEMO3",
      entry_type: "Exterior",
      length: 15,
      is_climate: false,
      is_inside: false,
      is_ada: false,
      has_power: false,
      has_unit_alarms: false,
      is_rentable: true,
      is_rented: true,
      is_on_waiting_list: false,
      standard_security_deposit: "0",
      door_type: null,
      is_mobile: false,
      filter_type_class: "parking",
      size: "park",
      description: "",
      square_feet: 0,
      unit_type_name: "Parking",
    },
  }, // Click Manage Unit in portal
  ledgerIdsForCharges: ["50272", "46732"], // Click Pay Unit. We filter out the charges/account_balance_details to show on receipt
  account: {
    id: "2",
    created_at: "2019-10-04T20:33:00.340Z",
    updated_at: "2019-10-04T20:33:00.340Z",
    version: 1,
    client_code: "architectures",
    name: "Terry, Gislason and Moen",
    privacy_policy: "Itaque laudantium reiciendis velit optio commodi ut aut dolor. Beatae architecto tempora qui quidem. Nobis aut sunt quo laudantium. Quidem vitae commodi ea.",
    company_information: "Investor",
    tagline: null,
  },
  website: {
    id: "b27da6df-0ca2-4c9e-8e45-873ecffc8d20",
    created_at: "2019-10-04T20:34:19.536Z",
    updated_at: "2019-11-01T18:49:42.000Z",
    version: 5,
    account_id: "2",
    allow_email_edit: false,
    allow_move_out: false,
    allow_autopay_changes: true,
    website_name: "Demo Website",
    hubspot_company_id: "098324d834",
    google_analytics: "GA_lksdjf_2314jds",
    autopay_terms: "asdf sdaf",
    icon_styles: "filled",
    social_icon_styles: null,
    recaptcha_key: null,
    domain: "asdtestclient.storagepug.com.s3-website-us-east-1.amazonaws.com",
    global_styles: {
      id: "1f7574fc-34a8-4812-8812-b105b2370280",
      created_at: "2019-10-04T20:34:19.687Z",
      updated_at: "2019-10-04T20:34:19.687Z",
      version: 1,
      color_main: "#f8f8f8",
      color_alt: "#f11721",
      color_background: "#f81421",
      color_text: "#aa1711",
      color_heading: "#abab21",
      color_link: "#f8cb21",
      color_alert: "#f81c21",
      color_success: "#aa1721",
      font_body: "Montserrat",
      font_heading: "Montserrat",
      button_main: "rounded-outline",
      button_main_color: "#2E81FF",
      button_main_text_color: "#2E81FF",
      button_main_hover_color: "#2E81FF",
      button_main_hover_text_color: "#ffffff",
      button_alt: "rounded",
      button_alt_color: "#2E81FF",
      button_alt_text_color: "#2E81FF",
      button_alt_hover_color: "#2E81FF",
      button_alt_hover_text_color: "#2E81FF",
      nav_color_background: "#8647B3",
      nav_color_text: "#ffffff",
      nav_color_text_hover: "#FFA226",
      footer_color_background: "#5868DB",
      footer_color_text: "#8647B3",
      footer_color_text_hover: "#8647B3",
      content: "Content yo.",
    },
    nav_logo: {
      id: "0e0ff442-45f1-473e-ab5e-f44707e29aea",
      created_at: "2019-10-04T20:33:29.231Z",
      updated_at: "2019-10-04T20:33:29.231Z",
      version: 1,
      account_id: "2",
      type: "picture",
      alt: "Maxime ea dolorem repudiandae sed sed quisquam perferendis enim.",
      src: "https://placeimg.com/100/100/any",
      title: "Atque culpa saepe sit omnis deserunt quidem exercitationem animi qui.",
      is_global: false,
      is_icon: false,
    },
    footer_logo: {
      id: "0e0ff442-45f1-473e-ab5e-f44707e29aea",
      created_at: "2019-10-04T20:33:29.231Z",
      updated_at: "2019-10-04T20:33:29.231Z",
      version: 1,
      account_id: "2",
      type: "picture",
      alt: "Maxime ea dolorem repudiandae sed sed quisquam perferendis enim.",
      src: "https://placeimg.com/100/100/any",
      title: "Atque culpa saepe sit omnis deserunt quidem exercitationem animi qui.",
      is_global: false,
      is_icon: false,
    },
    nav_component: {
      id: "09ffb9ce-510c-47c0-8d99-160cd8c76931",
      created_at: "2019-11-03T21:50:54.906Z",
      updated_at: "2019-11-03T21:50:54.906Z",
      version: 1,
      bit_component_name: "SectionAardvark",
      bit_component_url: "@bit/huddlehouse.pizza-palette.sections.section-aardvark",
      info: null,
      description: null,
      name: "SectionAardvark",
      type: "section",
      code: null,
      is_schema: true,
    },
    footer_component: {
      id: "09ffb9ce-510c-47c0-8d99-160cd8c76931",
      created_at: "2019-11-03T21:50:54.906Z",
      updated_at: "2019-11-03T21:50:54.906Z",
      version: 1,
      bit_component_name: "SectionAardvark",
      bit_component_url: "@bit/huddlehouse.pizza-palette.sections.section-aardvark",
      info: null,
      description: null,
      name: "SectionAardvark",
      type: "section",
      code: null,
      is_schema: true,
    },
    logo: {
      id: "0444b6c5-e746-4e32-88d1-086d088f2b00",
      created_at: "2019-10-04T20:33:27.847Z",
      updated_at: "2019-10-04T20:33:27.847Z",
      version: 1,
      account_id: "2",
      type: "picture",
      alt: "Quis laudantium architecto qui dolores a qui unde libero consequatur.",
      src: "https://www.storagepug.com/hubfs/Client%20Website%20Images/COWs%20of%20Delaware/cows-logo.png",
      title: "Harum sequi sed totam aliquam voluptatem omnis.",
      is_global: false,
      is_icon: false,
    },
    logo_square: {
      id: "0772496b-cb74-49b8-a934-d18ad4dac40b",
      created_at: "2019-10-04T20:33:28.793Z",
      updated_at: "2019-10-04T20:33:28.793Z",
      version: 1,
      account_id: "2",
      type: "picture",
      alt: "Illo ipsam esse ut recusandae.",
      src: "https://www.storagepug.com/hubfs/Client%20Website%20Images/COWs%20of%20Delaware/cows-logo-square.png",
      title: "Illo aliquid omnis dolores quasi odit dolores corporis labore sed.",
      is_global: false,
      is_icon: false,
    },
  },
  units: [
    [
      {
        id: "070c1a1b-9653-4730-843b-25053a1006c9",
        created_at: "2019-11-12T15:32:12.915Z",
        updated_at: "2019-11-12T16:10:29.000Z",
        version: 2,
        location_id: "2fe4aaa2-893f-4b9f-b2ec-fb806ea64a99",
        sitelink_id: "106505",
        rate: 27,
        push_rate: 27,
        street_rate: 27,
        street_push_rate: 27,
        tax: 0,
        floor: 1,
        unit_number: "131",
        width: 5,
        location_code: "AFTO",
        entry_type: "Exterior",
        length: 5,
        is_climate: false,
        is_inside: false,
        is_ada: false,
        has_power: false,
        has_unit_alarms: false,
        is_rentable: true,
        is_rented: true,
        is_on_waiting_list: false,
        standard_security_deposit: "20",
        door_type: "Rollup",
        is_mobile: false,
        filter_type_class: "non-climate-unit",
        size: "sm",
        width_length: "5 x 5",
        description: "",
        square_feet: 25,
        unit_type_name: "Non Climate Unit",
        type: {
          id: "7d7d6eea-8187-4f8f-94eb-6eabd05ca89f",
          created_at: "2019-11-12T16:10:11.569Z",
          updated_at: "2019-11-12T16:10:11.569Z",
          version: 1,
          name: "Non Climate Unit",
          sitelink_id: "1506",
          num_decimals_charges: 2,
          num_decimals_taxes: 2,
          tax_rate_1: 0,
          tax_rate_2: 0,
          is_charge_tax_rate_1: true,
          is_charge_tax_rate_2: true,
          is_mobile_unit: false,
          is_climate: false,
          is_power: false,
          is_alarm: false,
          is_inside: false,
          floor_num: 1,
          admin_fee: 20,
          reservation_fee: 20,
          is_exclude_from_insurance: false,
          discounts: [
            {
              id: "43b360e8-1816-4070-9e0b-62ebb9c429e4",
              created_at: "2019-11-12T13:39:54.618Z",
              updated_at: "2019-11-12T16:10:11.000Z",
              version: 5,
              site_id: "36333",
              type: 1,
              description: "",
              show_on: 0,
              require_prepay_months: 1,
              month_discount_is_applied: "2",
              discount_plan_global_id: 186471955,
              name: "50% off 1st full month",
              charge_category: "Rent",
              sitelink_id: "7145",
              never_expires: false,
              require_prepay: false,
              percentage_off: "50",
              fixed_price: 0,
              fixed_discount: 0,
              maximum_occupancy_percentage: 0,
              exclude_if_unit_total_less_than: 0,
              price: 0,
              is_rounded: false,
              rounded_type: 0,
              max_discount: 0,
              is_for_all_units: false,
              is_disabled: false,
              is_apply_at_move_in: false,
              is_prorated_at_move_in: false,
              tax_rate: 0,
              available_at: 432,
            },
          ],
        },
      },
      {
        id: "96924a09-4555-4b73-b73d-a89a55cb93cc",
        created_at: "2019-11-12T15:32:13.208Z",
        updated_at: "2019-11-12T16:10:29.000Z",
        version: 2,
        location_id: "2fe4aaa2-893f-4b9f-b2ec-fb806ea64a99",
        sitelink_id: "106569",
        rate: 60,
        push_rate: 60,
        street_rate: 60,
        street_push_rate: 60,
        tax: 0,
        floor: 1,
        unit_number: "190",
        width: 5,
        location_code: "AFTO",
        entry_type: null,
        length: 5,
        is_climate: true,
        is_inside: true,
        is_ada: false,
        has_power: false,
        has_unit_alarms: false,
        is_rentable: true,
        is_rented: true,
        is_on_waiting_list: false,
        standard_security_deposit: "0",
        door_type: "Rollup",
        is_mobile: false,
        filter_type_class: "climate-controlled-units",
        size: "sm",
        width_length: "5 x 5",
        description: "",
        square_feet: 25,
        unit_type_name: "Climate Controlled Units",
        type: {
          id: "04e4a98e-b479-4fb6-ad4e-c7c6f706ea2e",
          created_at: "2019-11-12T16:10:11.569Z",
          updated_at: "2019-11-12T16:10:11.569Z",
          version: 1,
          name: "Climate Controlled Units",
          sitelink_id: "1508",
          num_decimals_charges: 2,
          num_decimals_taxes: 2,
          tax_rate_1: 0,
          tax_rate_2: 0,
          is_charge_tax_rate_1: true,
          is_charge_tax_rate_2: true,
          is_mobile_unit: false,
          is_climate: true,
          is_power: false,
          is_alarm: false,
          is_inside: true,
          floor_num: 1,
          admin_fee: 20,
          reservation_fee: 20,
          is_exclude_from_insurance: false,
          discounts: [
            {
              id: "43b360e8-1816-4070-9e0b-62ebb9c429e4",
              created_at: "2019-11-12T13:39:54.618Z",
              updated_at: "2019-11-12T16:10:11.000Z",
              version: 5,
              site_id: "36333",
              type: 1,
              description: "",
              show_on: 0,
              require_prepay_months: 1,
              month_discount_is_applied: "2",
              discount_plan_global_id: 186471955,
              name: "50% off 1st full month",
              charge_category: "Rent",
              sitelink_id: "7145",
              never_expires: false,
              require_prepay: false,
              percentage_off: "50",
              fixed_price: 0,
              fixed_discount: 0,
              maximum_occupancy_percentage: 0,
              exclude_if_unit_total_less_than: 0,
              price: 0,
              is_rounded: false,
              rounded_type: 0,
              max_discount: 0,
              is_for_all_units: false,
              is_disabled: false,
              is_apply_at_move_in: false,
              is_prorated_at_move_in: false,
              tax_rate: 0,
              available_at: 432,
            },
          ],
        },
      },
    ],
    [
      {
        id: "15079f0c-fefe-44cc-aba2-56f68c84591f",
        created_at: "2019-11-12T15:32:12.940Z",
        updated_at: "2019-11-12T16:10:29.000Z",
        version: 2,
        location_id: "2fe4aaa2-893f-4b9f-b2ec-fb806ea64a99",
        sitelink_id: "106685",
        rate: 39,
        push_rate: 39,
        street_rate: 39,
        street_push_rate: 39,
        tax: 0,
        floor: 1,
        unit_number: "294",
        width: 5,
        location_code: "AFTO",
        entry_type: "Exterior",
        length: 8,
        is_climate: false,
        is_inside: false,
        is_ada: false,
        has_power: false,
        has_unit_alarms: false,
        is_rentable: true,
        is_rented: true,
        is_on_waiting_list: false,
        standard_security_deposit: "0",
        door_type: "Rollup",
        is_mobile: false,
        filter_type_class: "non-climate-unit",
        size: "sm",
        width_length: "5 x 8",
        description: "",
        square_feet: 40,
        unit_type_name: "Non Climate Unit",
        type: {
          id: "7d7d6eea-8187-4f8f-94eb-6eabd05ca89f",
          created_at: "2019-11-12T16:10:11.569Z",
          updated_at: "2019-11-12T16:10:11.569Z",
          version: 1,
          name: "Non Climate Unit",
          sitelink_id: "1506",
          num_decimals_charges: 2,
          num_decimals_taxes: 2,
          tax_rate_1: 0,
          tax_rate_2: 0,
          is_charge_tax_rate_1: true,
          is_charge_tax_rate_2: true,
          is_mobile_unit: false,
          is_climate: false,
          is_power: false,
          is_alarm: false,
          is_inside: false,
          floor_num: 1,
          admin_fee: 20,
          reservation_fee: 20,
          is_exclude_from_insurance: false,
          discounts: [
            {
              id: "43b360e8-1816-4070-9e0b-62ebb9c429e4",
              created_at: "2019-11-12T13:39:54.618Z",
              updated_at: "2019-11-12T16:10:11.000Z",
              version: 5,
              site_id: "36333",
              type: 1,
              description: "",
              show_on: 0,
              require_prepay_months: 1,
              month_discount_is_applied: "2",
              discount_plan_global_id: 186471955,
              name: "50% off 1st full month",
              charge_category: "Rent",
              sitelink_id: "7145",
              never_expires: false,
              require_prepay: false,
              percentage_off: "50",
              fixed_price: 0,
              fixed_discount: 0,
              maximum_occupancy_percentage: 0,
              exclude_if_unit_total_less_than: 0,
              price: 0,
              is_rounded: false,
              rounded_type: 0,
              max_discount: 0,
              is_for_all_units: false,
              is_disabled: false,
              is_apply_at_move_in: false,
              is_prorated_at_move_in: false,
              tax_rate: 0,
              available_at: 432,
            },
          ],
        },
      },
    ],
    [
      {
        id: "00a4c496-c672-4947-b629-e52e044b4699",
        created_at: "2019-11-12T15:32:12.976Z",
        updated_at: "2019-11-12T16:10:29.000Z",
        version: 2,
        location_id: "2fe4aaa2-893f-4b9f-b2ec-fb806ea64a99",
        sitelink_id: "106774",
        rate: 45,
        push_rate: 45,
        street_rate: 45,
        street_push_rate: 45,
        tax: 0,
        floor: 1,
        unit_number: "89",
        width: 5,
        location_code: "AFTO",
        entry_type: "Exterior",
        length: 10,
        is_climate: false,
        is_inside: false,
        is_ada: false,
        has_power: false,
        has_unit_alarms: false,
        is_rentable: true,
        is_rented: true,
        is_on_waiting_list: false,
        standard_security_deposit: "0",
        door_type: "Rollup",
        is_mobile: false,
        filter_type_class: "non-climate-unit",
        size: "sm",
        width_length: "5 x 10",
        description: "",
        square_feet: 50,
        unit_type_name: "Non Climate Unit",
        type: {
          id: "7d7d6eea-8187-4f8f-94eb-6eabd05ca89f",
          created_at: "2019-11-12T16:10:11.569Z",
          updated_at: "2019-11-12T16:10:11.569Z",
          version: 1,
          name: "Non Climate Unit",
          sitelink_id: "1506",
          num_decimals_charges: 2,
          num_decimals_taxes: 2,
          tax_rate_1: 0,
          tax_rate_2: 0,
          is_charge_tax_rate_1: true,
          is_charge_tax_rate_2: true,
          is_mobile_unit: false,
          is_climate: false,
          is_power: false,
          is_alarm: false,
          is_inside: false,
          floor_num: 1,
          admin_fee: 20,
          reservation_fee: 20,
          is_exclude_from_insurance: false,
          discounts: [
            {
              id: "43b360e8-1816-4070-9e0b-62ebb9c429e4",
              created_at: "2019-11-12T13:39:54.618Z",
              updated_at: "2019-11-12T16:10:11.000Z",
              version: 5,
              site_id: "36333",
              type: 1,
              description: "",
              show_on: 0,
              require_prepay_months: 1,
              month_discount_is_applied: "2",
              discount_plan_global_id: 186471955,
              name: "50% off 1st full month",
              charge_category: "Rent",
              sitelink_id: "7145",
              never_expires: false,
              require_prepay: false,
              percentage_off: "50",
              fixed_price: 0,
              fixed_discount: 0,
              maximum_occupancy_percentage: 0,
              exclude_if_unit_total_less_than: 0,
              price: 0,
              is_rounded: false,
              rounded_type: 0,
              max_discount: 0,
              is_for_all_units: false,
              is_disabled: false,
              is_apply_at_move_in: false,
              is_prorated_at_move_in: false,
              tax_rate: 0,
              available_at: 432,
            },
          ],
        },
      },
      {
        id: "169be0b1-fd3b-45e2-bf4d-2c435924dee6",
        created_at: "2019-11-12T15:32:13.209Z",
        updated_at: "2019-11-12T16:10:29.000Z",
        version: 2,
        location_id: "2fe4aaa2-893f-4b9f-b2ec-fb806ea64a99",
        sitelink_id: "106563",
        rate: 70,
        push_rate: 70,
        street_rate: 70,
        street_push_rate: 70,
        tax: 0,
        floor: 1,
        unit_number: "184",
        width: 5,
        location_code: "AFTO",
        entry_type: null,
        length: 10,
        is_climate: true,
        is_inside: true,
        is_ada: false,
        has_power: false,
        has_unit_alarms: false,
        is_rentable: true,
        is_rented: true,
        is_on_waiting_list: false,
        standard_security_deposit: "0",
        door_type: "Rollup",
        is_mobile: false,
        filter_type_class: "climate-controlled-units",
        size: "sm",
        width_length: "5 x 10",
        description: "",
        square_feet: 50,
        unit_type_name: "Climate Controlled Units",
        type: {
          id: "04e4a98e-b479-4fb6-ad4e-c7c6f706ea2e",
          created_at: "2019-11-12T16:10:11.569Z",
          updated_at: "2019-11-12T16:10:11.569Z",
          version: 1,
          name: "Climate Controlled Units",
          sitelink_id: "1508",
          num_decimals_charges: 2,
          num_decimals_taxes: 2,
          tax_rate_1: 0,
          tax_rate_2: 0,
          is_charge_tax_rate_1: true,
          is_charge_tax_rate_2: true,
          is_mobile_unit: false,
          is_climate: true,
          is_power: false,
          is_alarm: false,
          is_inside: true,
          floor_num: 1,
          admin_fee: 20,
          reservation_fee: 20,
          is_exclude_from_insurance: false,
          discounts: [
            {
              id: "43b360e8-1816-4070-9e0b-62ebb9c429e4",
              created_at: "2019-11-12T13:39:54.618Z",
              updated_at: "2019-11-12T16:10:11.000Z",
              version: 5,
              site_id: "36333",
              type: 1,
              description: "",
              show_on: 0,
              require_prepay_months: 1,
              month_discount_is_applied: "2",
              discount_plan_global_id: 186471955,
              name: "50% off 1st full month",
              charge_category: "Rent",
              sitelink_id: "7145",
              never_expires: false,
              require_prepay: false,
              percentage_off: "50",
              fixed_price: 0,
              fixed_discount: 0,
              maximum_occupancy_percentage: 0,
              exclude_if_unit_total_less_than: 0,
              price: 0,
              is_rounded: false,
              rounded_type: 0,
              max_discount: 0,
              is_for_all_units: false,
              is_disabled: false,
              is_apply_at_move_in: false,
              is_prorated_at_move_in: false,
              tax_rate: 0,
              available_at: 432,
            },
          ],
        },
      },
    ],
    [
      {
        id: "2b809dc7-6681-49b5-a47a-7644eb593acb",
        created_at: "2019-11-12T15:32:12.990Z",
        updated_at: "2019-11-12T16:10:29.000Z",
        version: 2,
        location_id: "2fe4aaa2-893f-4b9f-b2ec-fb806ea64a99",
        sitelink_id: "106512",
        rate: 55,
        push_rate: 55,
        street_rate: 55,
        street_push_rate: 55,
        tax: 0,
        floor: 1,
        unit_number: "138",
        width: 5,
        location_code: "AFTO",
        entry_type: "Exterior",
        length: 15,
        is_climate: false,
        is_inside: false,
        is_ada: false,
        has_power: false,
        has_unit_alarms: false,
        is_rentable: true,
        is_rented: true,
        is_on_waiting_list: false,
        standard_security_deposit: "0",
        door_type: "Rollup",
        is_mobile: false,
        filter_type_class: "non-climate-unit",
        size: "md",
        width_length: "5 x 15",
        description: "",
        square_feet: 75,
        unit_type_name: "Non Climate Unit",
        type: {
          id: "7d7d6eea-8187-4f8f-94eb-6eabd05ca89f",
          created_at: "2019-11-12T16:10:11.569Z",
          updated_at: "2019-11-12T16:10:11.569Z",
          version: 1,
          name: "Non Climate Unit",
          sitelink_id: "1506",
          num_decimals_charges: 2,
          num_decimals_taxes: 2,
          tax_rate_1: 0,
          tax_rate_2: 0,
          is_charge_tax_rate_1: true,
          is_charge_tax_rate_2: true,
          is_mobile_unit: false,
          is_climate: false,
          is_power: false,
          is_alarm: false,
          is_inside: false,
          floor_num: 1,
          admin_fee: 20,
          reservation_fee: 20,
          is_exclude_from_insurance: false,
          discounts: [
            {
              id: "43b360e8-1816-4070-9e0b-62ebb9c429e4",
              created_at: "2019-11-12T13:39:54.618Z",
              updated_at: "2019-11-12T16:10:11.000Z",
              version: 5,
              site_id: "36333",
              type: 1,
              description: "",
              show_on: 0,
              require_prepay_months: 1,
              month_discount_is_applied: "2",
              discount_plan_global_id: 186471955,
              name: "50% off 1st full month",
              charge_category: "Rent",
              sitelink_id: "7145",
              never_expires: false,
              require_prepay: false,
              percentage_off: "50",
              fixed_price: 0,
              fixed_discount: 0,
              maximum_occupancy_percentage: 0,
              exclude_if_unit_total_less_than: 0,
              price: 0,
              is_rounded: false,
              rounded_type: 0,
              max_discount: 0,
              is_for_all_units: false,
              is_disabled: false,
              is_apply_at_move_in: false,
              is_prorated_at_move_in: false,
              tax_rate: 0,
              available_at: 432,
            },
          ],
        },
      },
    ],
    [
      {
        id: "01d5d5af-7cdd-4fac-b336-c2f22385784b",
        created_at: "2019-11-12T15:32:13.047Z",
        updated_at: "2019-11-12T16:10:29.000Z",
        version: 2,
        location_id: "2fe4aaa2-893f-4b9f-b2ec-fb806ea64a99",
        sitelink_id: "106657",
        rate: 69,
        push_rate: 69,
        street_rate: 69,
        street_push_rate: 69,
        tax: 0,
        floor: 1,
        unit_number: "269",
        width: 10,
        location_code: "AFTO",
        entry_type: "Exterior",
        length: 10,
        is_climate: false,
        is_inside: false,
        is_ada: false,
        has_power: false,
        has_unit_alarms: false,
        is_rentable: true,
        is_rented: true,
        is_on_waiting_list: false,
        standard_security_deposit: "0",
        door_type: "Rollup",
        is_mobile: false,
        filter_type_class: "non-climate-unit",
        size: "md",
        width_length: "10 x 10",
        description: "",
        square_feet: 100,
        unit_type_name: "Non Climate Unit",
        type: {
          id: "7d7d6eea-8187-4f8f-94eb-6eabd05ca89f",
          created_at: "2019-11-12T16:10:11.569Z",
          updated_at: "2019-11-12T16:10:11.569Z",
          version: 1,
          name: "Non Climate Unit",
          sitelink_id: "1506",
          num_decimals_charges: 2,
          num_decimals_taxes: 2,
          tax_rate_1: 0,
          tax_rate_2: 0,
          is_charge_tax_rate_1: true,
          is_charge_tax_rate_2: true,
          is_mobile_unit: false,
          is_climate: false,
          is_power: false,
          is_alarm: false,
          is_inside: false,
          floor_num: 1,
          admin_fee: 20,
          reservation_fee: 20,
          is_exclude_from_insurance: false,
          discounts: [
            {
              id: "43b360e8-1816-4070-9e0b-62ebb9c429e4",
              created_at: "2019-11-12T13:39:54.618Z",
              updated_at: "2019-11-12T16:10:11.000Z",
              version: 5,
              site_id: "36333",
              type: 1,
              description: "",
              show_on: 0,
              require_prepay_months: 1,
              month_discount_is_applied: "2",
              discount_plan_global_id: 186471955,
              name: "50% off 1st full month",
              charge_category: "Rent",
              sitelink_id: "7145",
              never_expires: false,
              require_prepay: false,
              percentage_off: "50",
              fixed_price: 0,
              fixed_discount: 0,
              maximum_occupancy_percentage: 0,
              exclude_if_unit_total_less_than: 0,
              price: 0,
              is_rounded: false,
              rounded_type: 0,
              max_discount: 0,
              is_for_all_units: false,
              is_disabled: false,
              is_apply_at_move_in: false,
              is_prorated_at_move_in: false,
              tax_rate: 0,
              available_at: 432,
            },
          ],
        },
      },
      {
        id: "06494d88-01c4-445f-b621-724e080ecb24",
        created_at: "2019-11-12T15:32:13.217Z",
        updated_at: "2019-11-12T16:10:29.000Z",
        version: 2,
        location_id: "2fe4aaa2-893f-4b9f-b2ec-fb806ea64a99",
        sitelink_id: "106554",
        rate: 105,
        push_rate: 105,
        street_rate: 105,
        street_push_rate: 105,
        tax: 0,
        floor: 1,
        unit_number: "176",
        width: 10,
        location_code: "AFTO",
        entry_type: null,
        length: 10,
        is_climate: true,
        is_inside: true,
        is_ada: false,
        has_power: false,
        has_unit_alarms: false,
        is_rentable: true,
        is_rented: true,
        is_on_waiting_list: false,
        standard_security_deposit: "0",
        door_type: "Rollup",
        is_mobile: false,
        filter_type_class: "climate-controlled-units",
        size: "md",
        width_length: "10 x 10",
        description: "",
        square_feet: 100,
        unit_type_name: "Climate Controlled Units",
        type: {
          id: "04e4a98e-b479-4fb6-ad4e-c7c6f706ea2e",
          created_at: "2019-11-12T16:10:11.569Z",
          updated_at: "2019-11-12T16:10:11.569Z",
          version: 1,
          name: "Climate Controlled Units",
          sitelink_id: "1508",
          num_decimals_charges: 2,
          num_decimals_taxes: 2,
          tax_rate_1: 0,
          tax_rate_2: 0,
          is_charge_tax_rate_1: true,
          is_charge_tax_rate_2: true,
          is_mobile_unit: false,
          is_climate: true,
          is_power: false,
          is_alarm: false,
          is_inside: true,
          floor_num: 1,
          admin_fee: 20,
          reservation_fee: 20,
          is_exclude_from_insurance: false,
          discounts: [
            {
              id: "43b360e8-1816-4070-9e0b-62ebb9c429e4",
              created_at: "2019-11-12T13:39:54.618Z",
              updated_at: "2019-11-12T16:10:11.000Z",
              version: 5,
              site_id: "36333",
              type: 1,
              description: "",
              show_on: 0,
              require_prepay_months: 1,
              month_discount_is_applied: "2",
              discount_plan_global_id: 186471955,
              name: "50% off 1st full month",
              charge_category: "Rent",
              sitelink_id: "7145",
              never_expires: false,
              require_prepay: false,
              percentage_off: "50",
              fixed_price: 0,
              fixed_discount: 0,
              maximum_occupancy_percentage: 0,
              exclude_if_unit_total_less_than: 0,
              price: 0,
              is_rounded: false,
              rounded_type: 0,
              max_discount: 0,
              is_for_all_units: false,
              is_disabled: false,
              is_apply_at_move_in: false,
              is_prorated_at_move_in: false,
              tax_rate: 0,
              available_at: 432,
            },
          ],
        },
      },
    ],
    [
      {
        id: "05760d78-80e2-4418-84d1-c50b1c0d76c8",
        created_at: "2019-11-12T15:32:13.102Z",
        updated_at: "2019-11-12T16:10:29.000Z",
        version: 2,
        location_id: "2fe4aaa2-893f-4b9f-b2ec-fb806ea64a99",
        sitelink_id: "106703",
        rate: 82,
        push_rate: 82,
        street_rate: 82,
        street_push_rate: 82,
        tax: 0,
        floor: 1,
        unit_number: "31",
        width: 10,
        location_code: "AFTO",
        entry_type: "Exterior",
        length: 15,
        is_climate: false,
        is_inside: false,
        is_ada: false,
        has_power: false,
        has_unit_alarms: false,
        is_rentable: true,
        is_rented: true,
        is_on_waiting_list: false,
        standard_security_deposit: "0",
        door_type: "Rollup",
        is_mobile: false,
        filter_type_class: "non-climate-unit",
        size: "lg",
        width_length: "10 x 15",
        description: "",
        square_feet: 150,
        unit_type_name: "Non Climate Unit",
        type: {
          id: "7d7d6eea-8187-4f8f-94eb-6eabd05ca89f",
          created_at: "2019-11-12T16:10:11.569Z",
          updated_at: "2019-11-12T16:10:11.569Z",
          version: 1,
          name: "Non Climate Unit",
          sitelink_id: "1506",
          num_decimals_charges: 2,
          num_decimals_taxes: 2,
          tax_rate_1: 0,
          tax_rate_2: 0,
          is_charge_tax_rate_1: true,
          is_charge_tax_rate_2: true,
          is_mobile_unit: false,
          is_climate: false,
          is_power: false,
          is_alarm: false,
          is_inside: false,
          floor_num: 1,
          admin_fee: 20,
          reservation_fee: 20,
          is_exclude_from_insurance: false,
          discounts: [
            {
              id: "43b360e8-1816-4070-9e0b-62ebb9c429e4",
              created_at: "2019-11-12T13:39:54.618Z",
              updated_at: "2019-11-12T16:10:11.000Z",
              version: 5,
              site_id: "36333",
              type: 1,
              description: "",
              show_on: 0,
              require_prepay_months: 1,
              month_discount_is_applied: "2",
              discount_plan_global_id: 186471955,
              name: "50% off 1st full month",
              charge_category: "Rent",
              sitelink_id: "7145",
              never_expires: false,
              require_prepay: false,
              percentage_off: "50",
              fixed_price: 0,
              fixed_discount: 0,
              maximum_occupancy_percentage: 0,
              exclude_if_unit_total_less_than: 0,
              price: 0,
              is_rounded: false,
              rounded_type: 0,
              max_discount: 0,
              is_for_all_units: false,
              is_disabled: false,
              is_apply_at_move_in: false,
              is_prorated_at_move_in: false,
              tax_rate: 0,
              available_at: 432,
            },
          ],
        },
      },
      {
        id: "12257bbd-d220-4cc1-a8d5-d3ee1e9bb41f",
        created_at: "2019-11-12T15:32:13.242Z",
        updated_at: "2019-11-12T16:10:29.000Z",
        version: 2,
        location_id: "2fe4aaa2-893f-4b9f-b2ec-fb806ea64a99",
        sitelink_id: "106566",
        rate: 119,
        push_rate: 119,
        street_rate: 119,
        street_push_rate: 119,
        tax: 0,
        floor: 1,
        unit_number: "188",
        width: 10,
        location_code: "AFTO",
        entry_type: null,
        length: 15,
        is_climate: true,
        is_inside: true,
        is_ada: false,
        has_power: false,
        has_unit_alarms: false,
        is_rentable: true,
        is_rented: true,
        is_on_waiting_list: false,
        standard_security_deposit: "0",
        door_type: "Rollup",
        is_mobile: false,
        filter_type_class: "climate-controlled-units",
        size: "lg",
        width_length: "10 x 15",
        description: "",
        square_feet: 150,
        unit_type_name: "Climate Controlled Units",
        type: {
          id: "04e4a98e-b479-4fb6-ad4e-c7c6f706ea2e",
          created_at: "2019-11-12T16:10:11.569Z",
          updated_at: "2019-11-12T16:10:11.569Z",
          version: 1,
          name: "Climate Controlled Units",
          sitelink_id: "1508",
          num_decimals_charges: 2,
          num_decimals_taxes: 2,
          tax_rate_1: 0,
          tax_rate_2: 0,
          is_charge_tax_rate_1: true,
          is_charge_tax_rate_2: true,
          is_mobile_unit: false,
          is_climate: true,
          is_power: false,
          is_alarm: false,
          is_inside: true,
          floor_num: 1,
          admin_fee: 20,
          reservation_fee: 20,
          is_exclude_from_insurance: false,
          discounts: [
            {
              id: "43b360e8-1816-4070-9e0b-62ebb9c429e4",
              created_at: "2019-11-12T13:39:54.618Z",
              updated_at: "2019-11-12T16:10:11.000Z",
              version: 5,
              site_id: "36333",
              type: 1,
              description: "",
              show_on: 0,
              require_prepay_months: 1,
              month_discount_is_applied: "2",
              discount_plan_global_id: 186471955,
              name: "50% off 1st full month",
              charge_category: "Rent",
              sitelink_id: "7145",
              never_expires: false,
              require_prepay: false,
              percentage_off: "50",
              fixed_price: 0,
              fixed_discount: 0,
              maximum_occupancy_percentage: 0,
              exclude_if_unit_total_less_than: 0,
              price: 0,
              is_rounded: false,
              rounded_type: 0,
              max_discount: 0,
              is_for_all_units: false,
              is_disabled: false,
              is_apply_at_move_in: false,
              is_prorated_at_move_in: false,
              tax_rate: 0,
              available_at: 432,
            },
          ],
        },
      },
    ],
    [
      {
        id: "02d8ad8c-d53c-4d03-b063-5a25815bfa63",
        created_at: "2019-11-12T15:32:13.171Z",
        updated_at: "2019-11-12T16:10:29.000Z",
        version: 2,
        location_id: "2fe4aaa2-893f-4b9f-b2ec-fb806ea64a99",
        sitelink_id: "106706",
        rate: 99,
        push_rate: 99,
        street_rate: 99,
        street_push_rate: 99,
        tax: 0,
        floor: 1,
        unit_number: "312",
        width: 10,
        location_code: "AFTO",
        entry_type: "Exterior",
        length: 20,
        is_climate: false,
        is_inside: false,
        is_ada: false,
        has_power: false,
        has_unit_alarms: false,
        is_rentable: true,
        is_rented: true,
        is_on_waiting_list: false,
        standard_security_deposit: "0",
        door_type: "Rollup",
        is_mobile: false,
        filter_type_class: "non-climate-unit",
        size: "lg",
        width_length: "10 x 20",
        description: "",
        square_feet: 200,
        unit_type_name: "Non Climate Unit",
        type: {
          id: "7d7d6eea-8187-4f8f-94eb-6eabd05ca89f",
          created_at: "2019-11-12T16:10:11.569Z",
          updated_at: "2019-11-12T16:10:11.569Z",
          version: 1,
          name: "Non Climate Unit",
          sitelink_id: "1506",
          num_decimals_charges: 2,
          num_decimals_taxes: 2,
          tax_rate_1: 0,
          tax_rate_2: 0,
          is_charge_tax_rate_1: true,
          is_charge_tax_rate_2: true,
          is_mobile_unit: false,
          is_climate: false,
          is_power: false,
          is_alarm: false,
          is_inside: false,
          floor_num: 1,
          admin_fee: 20,
          reservation_fee: 20,
          is_exclude_from_insurance: false,
          discounts: [
            {
              id: "43b360e8-1816-4070-9e0b-62ebb9c429e4",
              created_at: "2019-11-12T13:39:54.618Z",
              updated_at: "2019-11-12T16:10:11.000Z",
              version: 5,
              site_id: "36333",
              type: 1,
              description: "",
              show_on: 0,
              require_prepay_months: 1,
              month_discount_is_applied: "2",
              discount_plan_global_id: 186471955,
              name: "50% off 1st full month",
              charge_category: "Rent",
              sitelink_id: "7145",
              never_expires: false,
              require_prepay: false,
              percentage_off: "50",
              fixed_price: 0,
              fixed_discount: 0,
              maximum_occupancy_percentage: 0,
              exclude_if_unit_total_less_than: 0,
              price: 0,
              is_rounded: false,
              rounded_type: 0,
              max_discount: 0,
              is_for_all_units: false,
              is_disabled: false,
              is_apply_at_move_in: false,
              is_prorated_at_move_in: false,
              tax_rate: 0,
              available_at: 432,
            },
          ],
        },
      },
      {
        id: "5426706c-cdec-4ea6-b6c1-2d69937f9daf",
        created_at: "2019-11-12T15:32:13.250Z",
        updated_at: "2019-11-12T16:10:29.000Z",
        version: 2,
        location_id: "2fe4aaa2-893f-4b9f-b2ec-fb806ea64a99",
        sitelink_id: "106564",
        rate: 160,
        push_rate: 160,
        street_rate: 160,
        street_push_rate: 160,
        tax: 0,
        floor: 1,
        unit_number: "185",
        width: 10,
        location_code: "AFTO",
        entry_type: null,
        length: 20,
        is_climate: true,
        is_inside: true,
        is_ada: false,
        has_power: false,
        has_unit_alarms: false,
        is_rentable: true,
        is_rented: true,
        is_on_waiting_list: false,
        standard_security_deposit: "0",
        door_type: "Rollup",
        is_mobile: false,
        filter_type_class: "climate-controlled-units",
        size: "lg",
        width_length: "10 x 20",
        description: "",
        square_feet: 200,
        unit_type_name: "Climate Controlled Units",
        type: {
          id: "04e4a98e-b479-4fb6-ad4e-c7c6f706ea2e",
          created_at: "2019-11-12T16:10:11.569Z",
          updated_at: "2019-11-12T16:10:11.569Z",
          version: 1,
          name: "Climate Controlled Units",
          sitelink_id: "1508",
          num_decimals_charges: 2,
          num_decimals_taxes: 2,
          tax_rate_1: 0,
          tax_rate_2: 0,
          is_charge_tax_rate_1: true,
          is_charge_tax_rate_2: true,
          is_mobile_unit: false,
          is_climate: true,
          is_power: false,
          is_alarm: false,
          is_inside: true,
          floor_num: 1,
          admin_fee: 20,
          reservation_fee: 20,
          is_exclude_from_insurance: false,
          discounts: [
            {
              id: "43b360e8-1816-4070-9e0b-62ebb9c429e4",
              created_at: "2019-11-12T13:39:54.618Z",
              updated_at: "2019-11-12T16:10:11.000Z",
              version: 5,
              site_id: "36333",
              type: 1,
              description: "",
              show_on: 0,
              require_prepay_months: 1,
              month_discount_is_applied: "2",
              discount_plan_global_id: 186471955,
              name: "50% off 1st full month",
              charge_category: "Rent",
              sitelink_id: "7145",
              never_expires: false,
              require_prepay: false,
              percentage_off: "50",
              fixed_price: 0,
              fixed_discount: 0,
              maximum_occupancy_percentage: 0,
              exclude_if_unit_total_less_than: 0,
              price: 0,
              is_rounded: false,
              rounded_type: 0,
              max_discount: 0,
              is_for_all_units: false,
              is_disabled: false,
              is_apply_at_move_in: false,
              is_prorated_at_move_in: false,
              tax_rate: 0,
              available_at: 432,
            },
          ],
        },
      },
    ],
    [
      {
        id: "258e76ef-b4e3-474a-8094-6e4302a286d7",
        created_at: "2019-11-12T15:32:13.193Z",
        updated_at: "2019-11-12T16:10:29.000Z",
        version: 2,
        location_id: "2fe4aaa2-893f-4b9f-b2ec-fb806ea64a99",
        sitelink_id: "106628",
        rate: 119,
        push_rate: 119,
        street_rate: 119,
        street_push_rate: 119,
        tax: 0,
        floor: 1,
        unit_number: "242",
        width: 10,
        location_code: "AFTO",
        entry_type: "Exterior",
        length: 25,
        is_climate: false,
        is_inside: false,
        is_ada: false,
        has_power: false,
        has_unit_alarms: false,
        is_rentable: true,
        is_rented: true,
        is_on_waiting_list: false,
        standard_security_deposit: "0",
        door_type: "Rollup",
        is_mobile: false,
        filter_type_class: "non-climate-unit",
        size: "xl",
        width_length: "10 x 25",
        description: "",
        square_feet: 250,
        unit_type_name: "Non Climate Unit",
        type: {
          id: "7d7d6eea-8187-4f8f-94eb-6eabd05ca89f",
          created_at: "2019-11-12T16:10:11.569Z",
          updated_at: "2019-11-12T16:10:11.569Z",
          version: 1,
          name: "Non Climate Unit",
          sitelink_id: "1506",
          num_decimals_charges: 2,
          num_decimals_taxes: 2,
          tax_rate_1: 0,
          tax_rate_2: 0,
          is_charge_tax_rate_1: true,
          is_charge_tax_rate_2: true,
          is_mobile_unit: false,
          is_climate: false,
          is_power: false,
          is_alarm: false,
          is_inside: false,
          floor_num: 1,
          admin_fee: 20,
          reservation_fee: 20,
          is_exclude_from_insurance: false,
          discounts: [
            {
              id: "43b360e8-1816-4070-9e0b-62ebb9c429e4",
              created_at: "2019-11-12T13:39:54.618Z",
              updated_at: "2019-11-12T16:10:11.000Z",
              version: 5,
              site_id: "36333",
              type: 1,
              description: "",
              show_on: 0,
              require_prepay_months: 1,
              month_discount_is_applied: "2",
              discount_plan_global_id: 186471955,
              name: "50% off 1st full month",
              charge_category: "Rent",
              sitelink_id: "7145",
              never_expires: false,
              require_prepay: false,
              percentage_off: "50",
              fixed_price: 0,
              fixed_discount: 0,
              maximum_occupancy_percentage: 0,
              exclude_if_unit_total_less_than: 0,
              price: 0,
              is_rounded: false,
              rounded_type: 0,
              max_discount: 0,
              is_for_all_units: false,
              is_disabled: false,
              is_apply_at_move_in: false,
              is_prorated_at_move_in: false,
              tax_rate: 0,
              available_at: 432,
            },
          ],
        },
      },
      {
        id: "b1c2639f-5baf-431c-997b-ef0aacd11a61",
        created_at: "2019-11-12T15:32:13.254Z",
        updated_at: "2019-11-12T16:10:29.000Z",
        version: 2,
        location_id: "2fe4aaa2-893f-4b9f-b2ec-fb806ea64a99",
        sitelink_id: "106662",
        rate: 170,
        push_rate: 170,
        street_rate: 170,
        street_push_rate: 170,
        tax: 0,
        floor: 1,
        unit_number: "273",
        width: 10,
        location_code: "AFTO",
        entry_type: null,
        length: 25,
        is_climate: true,
        is_inside: true,
        is_ada: false,
        has_power: false,
        has_unit_alarms: false,
        is_rentable: true,
        is_rented: true,
        is_on_waiting_list: false,
        standard_security_deposit: "0",
        door_type: "Rollup",
        is_mobile: false,
        filter_type_class: "climate-controlled-units",
        size: "xl",
        width_length: "10 x 25",
        description: "",
        square_feet: 250,
        unit_type_name: "Climate Controlled Units",
        type: {
          id: "04e4a98e-b479-4fb6-ad4e-c7c6f706ea2e",
          created_at: "2019-11-12T16:10:11.569Z",
          updated_at: "2019-11-12T16:10:11.569Z",
          version: 1,
          name: "Climate Controlled Units",
          sitelink_id: "1508",
          num_decimals_charges: 2,
          num_decimals_taxes: 2,
          tax_rate_1: 0,
          tax_rate_2: 0,
          is_charge_tax_rate_1: true,
          is_charge_tax_rate_2: true,
          is_mobile_unit: false,
          is_climate: true,
          is_power: false,
          is_alarm: false,
          is_inside: true,
          floor_num: 1,
          admin_fee: 20,
          reservation_fee: 20,
          is_exclude_from_insurance: false,
          discounts: [
            {
              id: "43b360e8-1816-4070-9e0b-62ebb9c429e4",
              created_at: "2019-11-12T13:39:54.618Z",
              updated_at: "2019-11-12T16:10:11.000Z",
              version: 5,
              site_id: "36333",
              type: 1,
              description: "",
              show_on: 0,
              require_prepay_months: 1,
              month_discount_is_applied: "2",
              discount_plan_global_id: 186471955,
              name: "50% off 1st full month",
              charge_category: "Rent",
              sitelink_id: "7145",
              never_expires: false,
              require_prepay: false,
              percentage_off: "50",
              fixed_price: 0,
              fixed_discount: 0,
              maximum_occupancy_percentage: 0,
              exclude_if_unit_total_less_than: 0,
              price: 0,
              is_rounded: false,
              rounded_type: 0,
              max_discount: 0,
              is_for_all_units: false,
              is_disabled: false,
              is_apply_at_move_in: false,
              is_prorated_at_move_in: false,
              tax_rate: 0,
              available_at: 432,
            },
          ],
        },
      },
    ],
    [
      {
        id: "a48fdbd8-2c6d-4891-b6fb-599078e840e3",
        created_at: "2019-11-12T15:32:13.203Z",
        updated_at: "2019-11-12T16:10:29.000Z",
        version: 2,
        location_id: "2fe4aaa2-893f-4b9f-b2ec-fb806ea64a99",
        sitelink_id: "106748",
        rate: 145,
        push_rate: 145,
        street_rate: 145,
        street_push_rate: 145,
        tax: 0,
        floor: 1,
        unit_number: "6437",
        width: 10,
        location_code: "AFTO",
        entry_type: "Exterior",
        length: 30,
        is_climate: false,
        is_inside: false,
        is_ada: false,
        has_power: false,
        has_unit_alarms: false,
        is_rentable: true,
        is_rented: true,
        is_on_waiting_list: false,
        standard_security_deposit: "0",
        door_type: "Rollup",
        is_mobile: false,
        filter_type_class: "non-climate-unit",
        size: "xl",
        width_length: "10 x 30",
        description: "",
        square_feet: 300,
        unit_type_name: "Non Climate Unit",
        type: {
          id: "7d7d6eea-8187-4f8f-94eb-6eabd05ca89f",
          created_at: "2019-11-12T16:10:11.569Z",
          updated_at: "2019-11-12T16:10:11.569Z",
          version: 1,
          name: "Non Climate Unit",
          sitelink_id: "1506",
          num_decimals_charges: 2,
          num_decimals_taxes: 2,
          tax_rate_1: 0,
          tax_rate_2: 0,
          is_charge_tax_rate_1: true,
          is_charge_tax_rate_2: true,
          is_mobile_unit: false,
          is_climate: false,
          is_power: false,
          is_alarm: false,
          is_inside: false,
          floor_num: 1,
          admin_fee: 20,
          reservation_fee: 20,
          is_exclude_from_insurance: false,
          discounts: [
            {
              id: "43b360e8-1816-4070-9e0b-62ebb9c429e4",
              created_at: "2019-11-12T13:39:54.618Z",
              updated_at: "2019-11-12T16:10:11.000Z",
              version: 5,
              site_id: "36333",
              type: 1,
              description: "",
              show_on: 0,
              require_prepay_months: 1,
              month_discount_is_applied: "2",
              discount_plan_global_id: 186471955,
              name: "50% off 1st full month",
              charge_category: "Rent",
              sitelink_id: "7145",
              never_expires: false,
              require_prepay: false,
              percentage_off: "50",
              fixed_price: 0,
              fixed_discount: 0,
              maximum_occupancy_percentage: 0,
              exclude_if_unit_total_less_than: 0,
              price: 0,
              is_rounded: false,
              rounded_type: 0,
              max_discount: 0,
              is_for_all_units: false,
              is_disabled: false,
              is_apply_at_move_in: false,
              is_prorated_at_move_in: false,
              tax_rate: 0,
              available_at: 432,
            },
          ],
        },
      },
    ],
    [
      {
        id: "4721dfc3-0c3f-447c-ad28-a8d842ce9567",
        created_at: "2019-11-12T15:32:13.206Z",
        updated_at: "2019-11-12T16:10:29.000Z",
        version: 2,
        location_id: "2fe4aaa2-893f-4b9f-b2ec-fb806ea64a99",
        sitelink_id: "106643",
        rate: 145,
        push_rate: 145,
        street_rate: 145,
        street_push_rate: 145,
        tax: 0,
        floor: 1,
        unit_number: "256",
        width: 15,
        location_code: "AFTO",
        entry_type: "Exterior",
        length: 20,
        is_climate: false,
        is_inside: false,
        is_ada: false,
        has_power: false,
        has_unit_alarms: false,
        is_rentable: true,
        is_rented: true,
        is_on_waiting_list: false,
        standard_security_deposit: "0",
        door_type: "Rollup",
        is_mobile: false,
        filter_type_class: "non-climate-unit",
        size: "xl",
        width_length: "15 x 20",
        description: "",
        square_feet: 300,
        unit_type_name: "Non Climate Unit",
        type: {
          id: "7d7d6eea-8187-4f8f-94eb-6eabd05ca89f",
          created_at: "2019-11-12T16:10:11.569Z",
          updated_at: "2019-11-12T16:10:11.569Z",
          version: 1,
          name: "Non Climate Unit",
          sitelink_id: "1506",
          num_decimals_charges: 2,
          num_decimals_taxes: 2,
          tax_rate_1: 0,
          tax_rate_2: 0,
          is_charge_tax_rate_1: true,
          is_charge_tax_rate_2: true,
          is_mobile_unit: false,
          is_climate: false,
          is_power: false,
          is_alarm: false,
          is_inside: false,
          floor_num: 1,
          admin_fee: 20,
          reservation_fee: 20,
          is_exclude_from_insurance: false,
          discounts: [
            {
              id: "43b360e8-1816-4070-9e0b-62ebb9c429e4",
              created_at: "2019-11-12T13:39:54.618Z",
              updated_at: "2019-11-12T16:10:11.000Z",
              version: 5,
              site_id: "36333",
              type: 1,
              description: "",
              show_on: 0,
              require_prepay_months: 1,
              month_discount_is_applied: "2",
              discount_plan_global_id: 186471955,
              name: "50% off 1st full month",
              charge_category: "Rent",
              sitelink_id: "7145",
              never_expires: false,
              require_prepay: false,
              percentage_off: "50",
              fixed_price: 0,
              fixed_discount: 0,
              maximum_occupancy_percentage: 0,
              exclude_if_unit_total_less_than: 0,
              price: 0,
              is_rounded: false,
              rounded_type: 0,
              max_discount: 0,
              is_for_all_units: false,
              is_disabled: false,
              is_apply_at_move_in: false,
              is_prorated_at_move_in: false,
              tax_rate: 0,
              available_at: 432,
            },
          ],
        },
      },
    ],
  ],
  user: {
    contact: {
      email: "pug@storagepug.com",
      first_name: "Pug",
      last_name: "StoragePug",
      middle_initial: "",
      phone_number: "",
      info: {
        web_pw: "SonP+9Emd2fUSRnGpySJiJxMPx3qQp2tKUH7ptWimjQ=###TN3mw5BbTvVFrAkd",
        addr_1: "110 Center Park Dr",
        addr_2: "",
        city: "Knoxville",
        region: "TN",
        postal_code: 37922,
        country: "",
        phone: "(111) 111-1111",
        cell_phone: "(111) 111-1111",
        company_name: "",
        is_commercial: false,
        is_company: false,
        has_active_ledger: false,
        license_number: "as3e",
        license_state: "AZ",
        gate_code: 9832982,
        alt_contact_first_name: "RIch",
        alt_contact_last_name: "Pug",
        alt_contact_phone: "(111) 111-1111",
        alt_contact_middle_initial: "",
        company_phone: "",
        dob: "2018-07-12T22:18:09.633Z",
        is_sms_opt_in: false,
        is_military: false,
        note: "registered on StoragePug website",
        mr_mrs: "",
        mr_mrs_alt: "",
        addr_1_alt: "",
        addr_2_alt: "",
        city_alt: "",
        region_alt: "",
        postal_code_alt: "",
        country_alt: "",
        mr_mrs_business: "",
        first_name_business: "",
        last_name_business: "",
        middle_initial_business: "",
        company_business: "",
        addr_1_business: "",
        addr_2_business: "",
        city_business: "",
        region_business: "",
        postal_code_business: "",
        country_business: "",
        phone_business: "",
        fax: "",
        pager: "",
        ssn: "",
        email_alt: "",
        relationship_alt: "",
        tenant_id: 87811,
        id: "128dff60-ca39-49f3-b15b-b24f8a6dd000",
        created_at: "2019-11-05T20:00:57.862Z",
        updated_at: "2019-11-05T20:00:57.862Z",
        version: 1,
      },
      is_tenant: false,
      account: {
        id: "2",
      },
      location: {
        id: "06384d03-2452-450d-8f30-4c4f6675c330",
      },
      id: "5455a197-07cb-4a69-a14d-4bb95e76a13c",
      updated_at: "2019-11-05T20:00:57.000Z",
      version: 2,
    },
    charges: {
      account_balance_details: [
        {
          ledger_id: 46732,
          sitelink_id: 9320,
          unit_name: "PB604",
          name: "Rent",
          start_date: "2019-06-01T04:00:00.000Z",
          end_date: "2019-07-01T03:59:59.997Z",
          amount_due: 127.2,
          price: 120,
          tax: 7.2,
        },
        {
          ledger_id: 46732,
          sitelink_id: 9320,
          unit_name: "PB604",
          name: "LateFees",
          start_date: "2019-05-16T04:00:00.000Z",
          end_date: "2019-05-16T04:00:00.000Z",
          amount_due: 175,
          price: 175,
          tax: 0,
        },
        {
          ledger_id: 48403,
          sitelink_id: 9286,
          unit_name: "PB638",
          name: "Rent",
          start_date: "2019-06-01T04:00:00.000Z",
          end_date: "2019-07-01T03:59:59.997Z",
          amount_due: 111.3,
          price: 105,
          tax: 6.3,
        },
        {
          ledger_id: 48403,
          sitelink_id: 9286,
          unit_name: "PB638",
          name: "LateFees",
          start_date: "2019-05-16T04:00:00.000Z",
          end_date: "2019-05-16T04:00:00.000Z",
          amount_due: 150,
          price: 150,
          tax: 0,
        },
        {
          ledger_id: 48402,
          sitelink_id: 7348,
          unit_name: "VP17",
          name: "Rent",
          start_date: "2019-06-01T04:00:00.000Z",
          end_date: "2019-07-01T03:59:59.997Z",
          amount_due: 445.2,
          price: 420,
          tax: 25.2,
        },
        {
          ledger_id: 48402,
          sitelink_id: 7348,
          unit_name: "VP17",
          name: "LateFees",
          start_date: "2019-05-16T04:00:00.000Z",
          end_date: "2019-05-16T04:00:00.000Z",
          amount_due: 150,
          price: 150,
          tax: 0,
        },
      ],
      total_due: 1251.5,
    },
    ledgers: [
      {
        ledger_id: 48402,
        sitelink_id: 7348,
        tenant_name: "Pug StoragePug",
        unit_number: "VP17",
        billing_frequency: "Monthly",
        rent: 60,
        move_in_date: "2018-10-29T14:38:57.000Z",
        total_due: 1251.5,
        is_overlocked: false,
        paid_thru: "2018-12-01T04:59:59.997Z",
        lease_num: 1935,
        access_code: 9832982,
        unit: {
          id: "9a45cee7-e6cd-452d-940d-886a8f3662b3",
          created_at: "2019-10-29T21:59:33.792Z",
          updated_at: "2019-11-01T18:55:02.000Z",
          version: 2,
          sitelink_id: "7348",
          rate: 60,
          push_rate: 60,
          street_rate: 60,
          street_push_rate: 60,
          tax: 6,
          floor: 1,
          unit_number: "VP17",
          width: 0,
          location_code: "DEMO3",
          entry_type: "Exterior",
          length: 15,
          is_climate: false,
          is_inside: false,
          is_ada: false,
          has_power: false,
          has_unit_alarms: false,
          is_rentable: true,
          is_rented: true,
          is_on_waiting_list: false,
          standard_security_deposit: "0",
          door_type: null,
          is_mobile: false,
          filter_type_class: "parking",
          size: "park",
          description: "",
          square_feet: 0,
          unit_type_name: "Parking",
        },
      },
      {
        ledger_id: 48403,
        sitelink_id: 9286,
        tenant_name: "Pug StoragePug",
        unit_number: "PB638",
        billing_frequency: "Monthly",
        rent: 15,
        move_in_date: "2018-10-29T15:16:36.000Z",
        total_due: 1251.5,
        is_overlocked: false,
        paid_thru: "2018-12-01T04:59:59.997Z",
        lease_num: 1936,
        access_code: 9832982,
        unit: {
          id: "64857704-fa3c-4d9b-ad66-7ac500740bd0",
          created_at: "2019-10-29T21:59:34.108Z",
          updated_at: "2019-11-01T18:55:01.000Z",
          version: 2,
          sitelink_id: "9286",
          rate: 15,
          push_rate: 15,
          street_rate: 15,
          street_push_rate: 15,
          tax: 6,
          floor: 1,
          unit_number: "PB638",
          width: 5,
          location_code: "DEMO3",
          entry_type: "Interior Hallway",
          length: 5,
          is_climate: false,
          is_inside: true,
          is_ada: false,
          has_power: false,
          has_unit_alarms: false,
          is_rentable: true,
          is_rented: true,
          is_on_waiting_list: false,
          standard_security_deposit: "50",
          door_type: "Swing",
          is_mobile: false,
          filter_type_class: "locker",
          size: "sm",
          description: "",
          square_feet: 25,
          unit_type_name: "Locker",
        },
      },
      {
        ledger_id: 50272,
        sitelink_id: 9180,
        tenant_name: "Pug StoragePug",
        unit_number: "PB142",
        billing_frequency: "Monthly",
        rent: 80,
        move_in_date: "2019-04-23T13:09:53.000Z",
        total_due: 1251.5,
        is_overlocked: false,
        paid_thru: "2019-06-01T03:59:59.997Z",
        lease_num: 2065,
        access_code: 9832982,
        unit: {
          id: "76203a35-d98d-4f96-b615-aaa3f7c03501",
          created_at: "2019-10-29T21:59:36.463Z",
          updated_at: "2019-11-01T18:55:02.000Z",
          version: 2,
          sitelink_id: "9180",
          rate: 80,
          push_rate: 80,
          street_rate: 80,
          street_push_rate: 80,
          tax: 6,
          floor: 1,
          unit_number: "PB142",
          width: 10,
          location_code: "DEMO3",
          entry_type: "Exterior",
          length: 10,
          is_climate: true,
          is_inside: true,
          is_ada: false,
          has_power: true,
          has_unit_alarms: true,
          is_rentable: true,
          is_rented: true,
          is_on_waiting_list: false,
          standard_security_deposit: "50",
          door_type: "Rollup",
          is_mobile: false,
          filter_type_class: "climate-control",
          size: "md",
          description: "",
          square_feet: 100,
          unit_type_name: "Climate Control",
        },
      },
    ],
    reservations: [
      {
        ledger_id: 46732,
        sitelink_id: 9320,
        tenant_name: "Pug StoragePug",
        unit_number: "PB604",
        billing_frequency: "Monthly",
        rent: 15,
        move_in_date: "2018-07-31T21:16:56.000Z",
        total_due: 1251.5,
        is_overlocked: false,
        paid_thru: "2018-11-01T03:59:59.997Z",
        lease_num: 1747,
        access_code: 9832982,
        unit: {
          id: "ed539eee-f181-425b-b1ce-80743a421acb",
          created_at: "2019-10-29T21:59:34.292Z",
          updated_at: "2019-11-01T18:55:04.000Z",
          version: 2,
          sitelink_id: "9320",
          rate: 15,
          push_rate: 15,
          street_rate: 15,
          street_push_rate: 15,
          tax: 6,
          floor: 1,
          unit_number: "PB604",
          width: 5,
          location_code: "DEMO3",
          entry_type: "Interior Hallway",
          length: 5,
          is_climate: false,
          is_inside: true,
          is_ada: false,
          has_power: false,
          has_unit_alarms: false,
          is_rentable: true,
          is_rented: true,
          is_on_waiting_list: false,
          standard_security_deposit: "50",
          door_type: "Swing",
          is_mobile: false,
          filter_type_class: "locker",
          size: "sm",
          description: "",
          square_feet: 25,
          unit_type_name: "Locker",
        },
      },
    ],
  },
  locations: [
    {
      id: "043524fe-cbdf-4894-8f21-202215f3ac5d",
      created_at: "2019-10-04T20:33:18.663Z",
      updated_at: "2019-11-12T16:09:42.000Z",
      version: 7,
      account_id: "2",
      name: "Monahan Throughway",
      url_slug: "",
      meta_title: "Realigned client-server open system",
      meta_tagline: "Extended high-level groupware",
      meta_description: "Intuitive bifurcated installation",
      software: "sitelink",
      phone_number: "(132) 917-2908 x0756",
      sms: "1-570-017-8214",
      email: "Alden93@gmail.com",
      units_quantity: null,
      facebook: "",
      yelp: "",
      google: "",
      twitter: "",
      sparefoot: "",
      address: {
        id: "4ebd3191-24b7-4964-9334-7555b1ff2d89",
        created_at: "2019-10-04T20:33:24.629Z",
        updated_at: "2019-10-04T20:33:24.629Z",
        version: 1,
        street_1: "123 Happy Street",
        street_2: "",
        state_province: "UT",
        city: "Diannaville",
        postal: "74773",
        country: "US",
        lat: "-22.8379",
        lon: "-99.7882",
      },
    },
    {
      id: "06384d03-2452-450d-8f30-4c4f6675c330",
      created_at: "2019-10-04T20:33:21.646Z",
      updated_at: "2019-11-12T16:09:00.000Z",
      version: 5,
      account_id: "2",
      name: "Otho Ville",
      url_slug: "",
      meta_title: "Virtual mobile standardization",
      meta_tagline: "Digitized well-modulated knowledge base",
      meta_description: "Enhanced cohesive workforce",
      software: "sitelink",
      phone_number: "(746) 003-1649 x3017",
      sms: "1-694-629-1327 x010",
      email: "Stanford_Bergnaum79@yahoo.com",
      units_quantity: null,
      facebook: "",
      yelp: "",
      google: "",
      twitter: "",
      sparefoot: "",
      address: {
        id: "58e90fda-e8fa-4121-b762-807ffc58f8b2",
        created_at: "2019-10-04T20:33:26.472Z",
        updated_at: "2019-10-04T20:33:26.472Z",
        version: 1,
        street_1: "",
        street_2: "",
        state_province: "NV",
        city: "Neilchester",
        postal: "95805",
        country: "US",
        lat: "27.1989",
        lon: "-151.1569",
      },
    },
    {
      id: "0b3bad7c-5e84-4605-850e-2beae625fb71",
      created_at: "2019-10-04T20:33:19.746Z",
      updated_at: "2019-11-06T22:58:24.000Z",
      version: 4,
      account_id: "2",
      name: "Anna Terrace",
      url_slug: "",
      meta_title: "Persevering hybrid structure",
      meta_tagline: "Front-line high-level challenge",
      meta_description: "Progressive logistical time-frame",
      software: "sitelink",
      phone_number: "(558) 919-4714",
      sms: "989-786-1792 x75891",
      email: "Maryam.Kutch@gmail.com",
      units_quantity: null,
      facebook: "",
      yelp: "",
      google: "",
      twitter: "",
      sparefoot: "",
      address: {
        id: "dfb6fba0-3f6b-4db6-b564-3bf4cb3a8532",
        created_at: "2019-10-04T20:33:25.552Z",
        updated_at: "2019-10-04T20:33:25.552Z",
        version: 1,
        street_1: "",
        street_2: "",
        state_province: "TN",
        city: "South Kayleehaven",
        postal: "98885-9825",
        country: "US",
        lat: "-69.7484",
        lon: "79.8836",
      },
    },
    {
      id: "1672196b-b5aa-49e5-ac39-1360cb047bc3",
      created_at: "2019-10-04T20:33:17.766Z",
      updated_at: "2019-11-06T22:58:31.000Z",
      version: 4,
      account_id: "2",
      name: "Watsica Path",
      url_slug: "",
      meta_title: "Team-oriented mobile function",
      meta_tagline: "Customizable non-volatile encoding",
      meta_description: "Profound 6th generation adapter",
      software: "sitelink",
      phone_number: "1-468-901-6852 x9749",
      sms: "1-901-732-1241",
      email: "Sven.West68@yahoo.com",
      units_quantity: null,
      facebook: "",
      yelp: "",
      google: "",
      twitter: "",
      sparefoot: "",
      address: {
        id: "64ef39cf-af26-4979-8bea-2bb13e56faf7",
        created_at: "2019-10-04T20:33:24.489Z",
        updated_at: "2019-10-04T20:33:24.489Z",
        version: 1,
        street_1: "",
        street_2: "",
        state_province: "HI",
        city: "Schaeferview",
        postal: "19909-3929",
        country: "US",
        lat: "82.1269",
        lon: "-61.9027",
      },
    },
    {
      id: "1d2853d5-0b34-40dc-bf18-809bedef5054",
      created_at: "2019-10-04T20:33:19.591Z",
      updated_at: "2019-11-06T22:58:38.000Z",
      version: 4,
      account_id: "2",
      name: "Gerald Stravenue",
      url_slug: "",
      meta_title: "User-centric fault-tolerant intranet",
      meta_tagline: "Persevering 24/7 software",
      meta_description: "Phased bi-directional application",
      software: "sitelink",
      phone_number: "315.770.6782",
      sms: "1-295-058-8552",
      email: "Lambert80@hotmail.com",
      units_quantity: null,
      facebook: "",
      yelp: "",
      google: "",
      twitter: "",
      sparefoot: "",
      address: {
        id: "b867130d-2d5a-4c18-a743-562a7a849a94",
        created_at: "2019-10-04T20:33:25.391Z",
        updated_at: "2019-10-04T20:33:25.391Z",
        version: 1,
        street_1: "",
        street_2: "",
        state_province: "ND",
        city: "Prohaskabury",
        postal: "13372",
        country: "US",
        lat: "-85.9559",
        lon: "-4.4311",
      },
    },
    {
      id: "27f46cb2-f5d7-4488-ab8a-3bb0279a7217",
      created_at: "2019-10-04T20:33:19.198Z",
      updated_at: "2019-10-31T08:22:56.000Z",
      version: 3,
      account_id: "2",
      name: "Shanahan Square",
      url_slug: "",
      meta_title: "Object-based homogeneous open architecture",
      meta_tagline: "Ergonomic real-time conglomeration",
      meta_description: "Quality-focused stable capability",
      software: "",
      phone_number: "1-527-461-8080 x9445",
      sms: "152-807-2569",
      email: "Rosa68@yahoo.com",
      units_quantity: null,
      facebook: "",
      yelp: "",
      google: "",
      twitter: "",
      sparefoot: "",
      address: {
        id: "cd11e1b0-918e-4758-b7f8-0069847cd375",
        created_at: "2019-10-04T20:33:24.918Z",
        updated_at: "2019-10-04T20:33:24.918Z",
        version: 1,
        street_1: "",
        street_2: "",
        state_province: "HI",
        city: "South Meagan",
        postal: "16936",
        country: "US",
        lat: "-64.4149",
        lon: "100.6969",
      },
    },
    {
      id: "2fe4aaa2-893f-4b9f-b2ec-fb806ea64a99",
      created_at: "2019-10-04T20:33:20.802Z",
      updated_at: "2019-11-12T13:39:15.000Z",
      version: 8,
      account_id: "2",
      name: "Richard Storage",
      url_slug: "richard-storage",
      meta_title: "Vision-oriented high-level adapter",
      meta_tagline: "Multi-channelled systemic software",
      meta_description: "Exclusive user-facing challenge",
      software: "sitelink",
      phone_number: "(821) 805-6023",
      sms: "(724) 700-8708 x014",
      email: "Miracle.Douglas@yahoo.com",
      units_quantity: null,
      facebook: "",
      yelp: "",
      google: "",
      twitter: "",
      sparefoot: "",
      address: {
        id: "3803b1cb-e38b-421e-8f2c-1dc273df34e8",
        created_at: "2019-10-04T20:33:26.148Z",
        updated_at: "2019-11-06T22:23:46.000Z",
        version: 2,
        street_1: "Suite 140",
        street_2: "",
        state_province: "NE",
        city: "Knoxville",
        postal: "37923",
        country: "United States",
        lat: "58.6409",
        lon: "35.6678",
      },
    },
    {
      id: "350c5e4a-e6fe-4a96-a19d-5339ad8a9eaf",
      created_at: "2019-10-04T20:33:19.417Z",
      updated_at: "2019-10-31T08:34:24.000Z",
      version: 3,
      account_id: "2",
      name: "Harvey Loaf",
      url_slug: "",
      meta_title: "Switchable solution-oriented infrastructure",
      meta_tagline: "Compatible directional instruction set",
      meta_description: "Configurable actuating circuit",
      software: "",
      phone_number: "1-002-895-1237 x21983",
      sms: "1-426-479-2312 x468",
      email: "Fannie.Kemmer@hotmail.com",
      units_quantity: null,
      facebook: "",
      yelp: "",
      google: "",
      twitter: "",
      sparefoot: "",
      address: {
        id: "ee51b55c-530d-45e0-bb86-ff492201f385",
        created_at: "2019-10-04T20:33:25.235Z",
        updated_at: "2019-10-04T20:33:25.235Z",
        version: 1,
        street_1: "",
        street_2: "",
        state_province: "NM",
        city: "Pamelahaven",
        postal: "84172-7589",
        country: "US",
        lat: "-84.2545",
        lon: "131.4423",
      },
    },
    {
      id: "4a6c7a7b-a06f-499b-b124-3fa26670ae8d",
      created_at: "2019-10-04T20:33:22.034Z",
      updated_at: "2019-10-04T20:33:56.000Z",
      version: 2,
      account_id: "2",
      name: "Kiehn Union",
      url_slug: "",
      meta_title: "Mandatory content-based utilisation",
      meta_tagline: "Quality-focused methodical conglomeration",
      meta_description: "Re-contextualized homogeneous projection",
      software: "",
      phone_number: "(668) 707-0178",
      sms: "996.594.0278 x59064",
      email: "Angie_Russel@yahoo.com",
      units_quantity: null,
      facebook: "",
      yelp: "",
      google: "",
      twitter: "",
      sparefoot: "",
      address: {
        id: "27e34a34-ee9a-467e-9aa7-b89127e91e98",
        created_at: "2019-10-04T20:33:27.108Z",
        updated_at: "2019-10-04T20:33:27.108Z",
        version: 1,
        street_1: "",
        street_2: "",
        state_province: "ND",
        city: "Lake Hector",
        postal: "20918",
        country: "US",
        lat: "-36.4104",
        lon: "-60.9043",
      },
    },
    {
      id: "5813eb5c-3614-4c7c-bed4-ba97bc20a8a5",
      created_at: "2019-10-04T20:33:20.625Z",
      updated_at: "2019-10-31T08:34:38.000Z",
      version: 3,
      account_id: "2",
      name: "Raven Plains",
      url_slug: "",
      meta_title: "Phased directional project",
      meta_tagline: "Profound reciprocal moratorium",
      meta_description: "Programmable bottom-line pricing structure",
      software: "",
      phone_number: "1-337-892-8114",
      sms: "(976) 969-2242",
      email: "Betty88@hotmail.com",
      units_quantity: null,
      facebook: "",
      yelp: "",
      google: "",
      twitter: "",
      sparefoot: "",
      address: {
        id: "76887426-15e1-4c3c-8b24-7db4a34b58fb",
        created_at: "2019-10-04T20:33:25.999Z",
        updated_at: "2019-10-04T20:33:25.999Z",
        version: 1,
        street_1: "123 Street",
        street_2: "",
        state_province: "FL",
        city: "Schneiderport",
        postal: "41702-3441",
        country: "US",
        lat: "-20.2863",
        lon: "145.6834",
      },
    },
    {
      id: "5f975fee-150e-4395-8b87-f77b876ba7b9",
      created_at: "2019-10-04T20:33:22.247Z",
      updated_at: "2019-10-31T15:09:00.000Z",
      version: 3,
      account_id: "2",
      name: "Hauck Divide",
      url_slug: "",
      meta_title: "Horizontal high-level collaboration",
      meta_tagline: "Seamless systemic info-mediaries",
      meta_description: "De-engineered intangible data-warehouse",
      software: "",
      phone_number: "130.699.6002 x8763",
      sms: "619.084.9329 x09923",
      email: "Juvenal_Schuppe@yahoo.com",
      units_quantity: null,
      facebook: "",
      yelp: "",
      google: "",
      twitter: "",
      sparefoot: "",
      address: {
        id: "25dfc850-237d-46ff-8b8d-9b9fe702c84f",
        created_at: "2019-10-04T20:33:27.250Z",
        updated_at: "2019-10-04T20:33:27.250Z",
        version: 1,
        street_1: "",
        street_2: "",
        state_province: "WA",
        city: "East Annaport",
        postal: "16669",
        country: "US",
        lat: "-65.7899",
        lon: "12.5862",
      },
    },
    {
      id: "7ffc475b-a178-4741-bb95-15bd9c65d39d",
      created_at: "2019-10-04T20:33:19.995Z",
      updated_at: "2019-10-04T20:33:45.000Z",
      version: 2,
      account_id: "2",
      name: "Reginald Glens",
      url_slug: "",
      meta_title: "Stand-alone bandwidth-monitored protocol",
      meta_tagline: "Re-contextualized incremental task-force",
      meta_description: "Centralized multimedia service-desk",
      software: "",
      phone_number: "(720) 970-7969 x89533",
      sms: "1-186-343-7639",
      email: "Esther86@gmail.com",
      units_quantity: null,
      facebook: "",
      yelp: "",
      google: "",
      twitter: "",
      sparefoot: "",
      address: {
        id: "c0adfa51-e5a8-43a0-aece-a39c2535e80d",
        created_at: "2019-10-04T20:33:25.704Z",
        updated_at: "2019-10-04T20:33:25.704Z",
        version: 1,
        street_1: "",
        street_2: "",
        state_province: "DE",
        city: "New Westonmouth",
        postal: "90968",
        country: "US",
        lat: "88.4208",
        lon: "145.0455",
      },
    },
    {
      id: "90f09e56-46e6-477a-b255-46143dccc58e",
      created_at: "2019-10-04T20:33:20.955Z",
      updated_at: "2019-10-04T20:33:47.000Z",
      version: 2,
      account_id: "2",
      name: "Vandervort Wells",
      url_slug: "",
      meta_title: "Networked executive array",
      meta_tagline: "Adaptive multimedia adapter",
      meta_description: "Team-oriented demand-driven local area network",
      software: "",
      phone_number: "105-085-6123 x88642",
      sms: "(729) 055-0240 x8539",
      email: "Anahi.Rolfson76@hotmail.com",
      units_quantity: null,
      facebook: "",
      yelp: "",
      google: "",
      twitter: "",
      sparefoot: "",
      address: {
        id: "fc87a536-89bf-4ce4-9db1-2929533d7fea",
        created_at: "2019-10-04T20:33:26.292Z",
        updated_at: "2019-10-04T20:33:26.292Z",
        version: 1,
        street_1: "",
        street_2: "",
        state_province: "IN",
        city: "West Lonny",
        postal: "41676",
        country: "US",
        lat: "-78.0202",
        lon: "-127.2832",
      },
    },
    {
      id: "9147f122-de7b-484d-89b3-59bff8fac22d",
      created_at: "2019-10-04T20:33:21.859Z",
      updated_at: "2019-10-04T20:33:53.000Z",
      version: 2,
      account_id: "2",
      name: "Vernice Run",
      url_slug: "",
      meta_title: "Total multimedia implementation",
      meta_tagline: "Adaptive responsive complexity",
      meta_description: "Synergistic high-level extranet",
      software: "",
      phone_number: "200-934-5537 x223",
      sms: "521-088-3202",
      email: "Kelsi.Green@yahoo.com",
      units_quantity: null,
      facebook: "",
      yelp: "",
      google: "",
      twitter: "",
      sparefoot: "",
      address: {
        id: "f83d797b-9dd8-403f-be11-f5e2552bfff8",
        created_at: "2019-10-04T20:33:26.619Z",
        updated_at: "2019-10-04T20:33:26.619Z",
        version: 1,
        street_1: "",
        street_2: "",
        state_province: "MI",
        city: "South Jeramie",
        postal: "98557-7835",
        country: "US",
        lat: "37.1561",
        lon: "178.1975",
      },
    },
    {
      id: "f464cf5e-fd52-4d0a-93b0-f22d42aa3f94",
      created_at: "2019-10-04T20:33:20.393Z",
      updated_at: "2019-10-04T20:33:46.000Z",
      version: 2,
      account_id: "2",
      name: "Rocky Harbor",
      url_slug: "",
      meta_title: "Expanded 24 hour toolset",
      meta_tagline: "Adaptive real-time complexity",
      meta_description: "Vision-oriented transitional service-desk",
      software: "",
      phone_number: "1-564-812-1175",
      sms: "147-461-9378",
      email: "Fausto43@gmail.com",
      units_quantity: null,
      facebook: "",
      yelp: "",
      google: "",
      twitter: "",
      sparefoot: "",
      address: {
        id: "3819b171-7c97-4427-a83b-7bc8471d07a9",
        created_at: "2019-10-04T20:33:25.856Z",
        updated_at: "2019-10-04T20:33:25.856Z",
        version: 1,
        street_1: "",
        street_2: "",
        state_province: "CO",
        city: "Ethylmouth",
        postal: "71538-7186",
        country: "US",
        lat: "-65.3848",
        lon: "137.1988",
      },
    },
    {
      id: "fc95d6b9-7161-4bdd-85eb-b2e6122d5abb",
      created_at: "2019-10-04T20:33:18.835Z",
      updated_at: "2019-10-04T20:33:40.000Z",
      version: 2,
      account_id: "2",
      name: "West Well",
      url_slug: "",
      meta_title: "Synergistic content-based implementation",
      meta_tagline: "Open-source holistic local area network",
      meta_description: "Switchable high-level open system",
      software: "",
      phone_number: "996-562-8624",
      sms: "766.662.6133",
      email: "Ethyl.Kshlerin@gmail.com",
      units_quantity: null,
      facebook: "",
      yelp: "",
      google: "",
      twitter: "",
      sparefoot: "",
      address: {
        id: "6164dd34-d3a6-46f1-937b-393ea4dbcfc2",
        created_at: "2019-10-04T20:33:24.774Z",
        updated_at: "2019-10-04T20:33:24.774Z",
        version: 1,
        street_1: "",
        street_2: "",
        state_province: "TX",
        city: "Goodwinview",
        postal: "96803",
        country: "US",
        lat: "30.3571",
        lon: "-156.5756",
      },
    },
  ],
  pages: [],
  selectedPage: {},
})

const getters = {
  user: (state) => state.user,
  getUnits: (state) => {
    return state.units
  },
  getSelectedPage: (state) => {
    return state.selectedPage
  },
  getHasDataLoaded: (state) => {
    return state.has_data_loaded
  },
  getSelectedLocation: (state) => {
    return state.selectedLocation
  },
}

const mutations = {
  [SET_USER](state, payload) {
    state.user = payload
    state.isUserLoggedIn = true
  },
  [SET_WEBSITE](state, payload) {
    state.website = payload
  },
  [SET_ACCOUNT](state, payload) {
    state.account = payload
  },
  [SET_LOCATIONS](state, payload) {
    state.locations = payload
  },
  [SET_UNITS](state, payload) {
    state.units = payload
  },
  [SET_PAGES](state, payload) {
    state.pages = payload
  },
  [SET_SELECTED_LOCATION](state, payload) {
    state.selectedLocation = payload
  },
  [SET_SELECTED_PAGE](state, payload) {
    state.selectedPage = payload
  },
  [UPDATE_PAGE_INFO](state, payload) {
    const index = state.pages.findIndex((page) => page.id === payload.id)
    state.pages[index] = payload
  },
}

const actions = {
  login({ state, commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const account = state.account.id
        const location = state.selectedLocation.id

        const { data: res } = await SitelinkApi.login(payload, account, location)
        // console.log(res)
        if (res.success === true) {
          commit("SET_USER", res.data)
        } else {
          reject(res.data.Ret_Msg ? res.data.Ret_Msg : "Could not log you in.")
        }
        resolve()
      } catch (error) {
        Vue.rollbar.error('Store: Error logging in user', error)
        reject(error)
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
