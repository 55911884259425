import { generateApiUrl } from "@/utils/helpers"
import axios from "axios"
import Vue from "vue"

export const state = () => ({
  featuresIndex: {},
})

export const mutations = {
  addFeature(state, payload) {
    state.featuresIndex[payload.internal_name] = payload
  },
  addFeatures(state, payload) {
    state.featuresIndex = { ...state.featuresIndex, ...payload }
  }
}

export const getters = {
  getFeature: (state) => (internal_name) => {
    return state.featuresIndex[internal_name]
  },
}

const actions = {
  setBetaAndComingSoonFeatures({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = generateApiUrl(`feature/beta-or-coming-soon`)
        const { data: res } = await axios.get(url, { headers: { 'x-purge': true } })
        let featuresIndex = {}
        for (let feature of res.data) {
          featuresIndex[feature.internal_name] = feature
        }
        commit("addFeatures", featuresIndex)
        return resolve(featuresIndex)
      } catch (e) {
        Vue.rollbar.error(e)
        return reject(e)
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
